import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';

function FirstPage(props) {
  const { formik } = props;
  const { openOtp } = props;
  return (
    <div>
      <TextField
        fullWidth
        id='email'
        name='email'
        label='Email'
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />

      <Box
        sx={{
          ...(!openOtp && { display: 'none' }),
        }}
      >
        <TextField
          fullWidth
          id='emailOtp'
          name='emailOtp'
          label='Email OTP'
          type='password'
          value={formik.values.emailOtp}
          onChange={formik.handleChange}
          error={formik.touched.emailOtp && Boolean(formik.errors.emailOtp)}
          helperText={formik.touched.emailOtp && formik.errors.emailOtp}
        />
      </Box>
    </div>
  );
}

export default FirstPage;
