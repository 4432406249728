import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

function EmployeeToolbar() {
  return (
    <Box sx={{ marginTop: 2 }}>
      <Card sx={{ padding: 4 }}>
        <Box>
          <Typography variant="h6" component="div">
            Table shows hospital users signed up for the app
          </Typography>
          <Typography
            sx={{ fontSize: 14, marginTop: 1 }}
            color="text.secondary"
          >
            First add employee mobile number to access control table. Only after
            that, the person can access and onboard in DailyDoc mobile app. Only
            onboarded employees will appear below.
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

export default EmployeeToolbar;
