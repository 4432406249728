import api from '../../utilities/api';
import {
  getDepartmentsAction,
  getDefaultDepartmentsAction,
} from '../../redux/departments/departmentsAction';

import store from '../../redux/store';

export const getDepartmentsData = async (hospitalId) => {
  const url = `/hospitals/${hospitalId}/departments`;
  const response = await api.get(url);
  if (response) store.dispatch(getDepartmentsAction(response.data.data));
};

export const getDefaultDepartments = async () => {
  const url = '/onboarding/ob4/departments';
  const response = await api.get(url);
  if (response) store.dispatch(getDefaultDepartmentsAction(response.data.data));
};
