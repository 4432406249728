import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

export default function CardItem({ feature, planTitle }) {
  return (
    <Box
      style={{
        marginTop: 24,
        display: "flex",
        alignItems: "center",
      }}
    >
      {feature?.plan?.includes(planTitle) ? (
        <DoneIcon color="success" fontSize="medium" sx={{ marginRight: 4 }} />
      ) : (
        <CloseIcon
          color="primary"
          fontSize="medium"
          sx={{ marginRight: 1.5 }}
        />
      )}
      <Typography variant="subtital2">{feature?.feature}</Typography>
    </Box>
  );
}
