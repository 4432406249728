import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";
import AccountMenu from "../../components/navbar/AccountMenu";

function AccountLayout() {
  return (
    <Container sx={{ display: "flex" }} maxWidth="xl">
      <Box>
        <AccountMenu />
      </Box>
      <Box px={2} sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Container>
  );
}

export default AccountLayout;
