import { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Paper from "@mui/material/Paper";
import EditBed from "./EditBed";
import Tooltip from "@mui/material/Tooltip";
import DialogAlert from "../alerts/DialogAlert";

import { getBedsData } from "./utilBeds";

import api from "../../utilities/api";

const validationSchema = yup.object({
  inputWard: yup.string("Provide a ward or floor").required("Required"),
  bed: yup.string("Provide bed number").required("Required"),
});

export default function BedsTable() {
  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setisDeleteDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState([]);
  const [rowToDelete, setRowToDelete] = useState([]);
  const [deleteErr, setdeleteErr] = useState("");

  const hospital = useSelector((state) => state.auth.hospital);
  const { wards } = useSelector((state) => state.wards);
  const { beds } = useSelector((state) => state.beds);

  const initialValues = {
    ward: wards.find((e) => e.name === rowToEdit?.wardName) || wards[0],
    inputWard: rowToEdit.wardName || "",
    bed: rowToEdit.name || "",
    bedToEdit: "beast",
  };

  useEffect(() => {
    getBedsData(hospital._id);
    return () => {};
  }, [hospital]);

  // for formik set up
  const onSubmit = async (values, actions) => {
    const { bed, ward } = values;
    const url = `/hospitals/${hospital._id}/wards/${ward._id}/beds/${rowToEdit?._id}`;
    try {
      const formData = {
        beds: {
          name: bed,
        },
      };
      const response = await api.patch(url, formData);
      if (response) {
        getBedsData(hospital._id);
        setisEditDialogOpen(false);
      }
    } catch (err) {
      actions.setFieldError("bed", err.response?.data.message);
    }
  };

  // close dialoge
  const closeEditDialog = () => {
    setisEditDialogOpen(false);
    setRowToEdit([]);
  };
  const closeDeleteDialog = () => {
    setisDeleteDialogOpen(false);
    setdeleteErr("");
    // setRowToDelete([]);
  };
  // edit row
  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      setisEditDialogOpen(true);
    },
    []
  );

  // open delete dialogalert
  const openDeleteDialog = useCallback(
    (id, row) => () => {
      setRowToDelete(row);
      setisDeleteDialogOpen(true);
    },
    []
  );
  // delete bed
  const deleteBed = async () => {
    try {
      const url = `/hospitals/${hospital._id}/beds`;
      const formData = { beds: [rowToDelete._id], hospital: hospital._id };
      const response = await api.delete(url, { data: formData });
      if (response) {
        getBedsData(hospital._id);
        setisDeleteDialogOpen(false);
      }
    } catch (err) {
      if (err) setdeleteErr(err.response?.data.message);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });
  const columns = useMemo(
    () => [
      {
        field: "wardName",
        headerName: "Ward",
        description: "Ward",
        type: "string",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "name",
        headerName: "Bed",
        description: "Bed",
        type: "string",
        minWidth: 200,
        flex: 1,
      },
      {
        field: "actions",
        headerName: "Actions",
        description: "Modify table",
        type: "actions",
        minWidth: 120,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete bed">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit bed">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    [openDeleteDialog, editRow]
  );

  return (
    <Paper
      sx={{
        height: 600,
        width: "100%",
        marginTop: 2,
        padding: 4,
        // boxShadow: 8,
      }}
    >
      <DataGrid
        sx={{
          border: 2,
          borderColor: "divider",
          "& .MuiDataGrid-cell": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        columns={columns}
        rows={beds}
      />
      <DialogAlert
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        handleYes={deleteBed}
        title={`Delete bed ${rowToDelete?.name}`}
        msg={
          deleteErr ||
          `Are you sure you want to delete bed: ${rowToDelete?.name}?`
        }
      />
      <EditBed
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        formik={formik}
      />
    </Paper>
  );
}
