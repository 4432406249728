import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import CircularProgress from "@mui/material/CircularProgress";

import EmailMobile from "./EmailMobile";
import DDLogo from "../icons/ddLogo";
import MobileEmailOtp from "../login/MobileEmailOtp";

import api from "../../utilities/api";
import setAuthToken from "../../utilities/setAuthToken";

import { countries } from "../../utilities/countries";
import NewPassword from "./NewPassword";
import PasswordSuccess from "./PasswordSuccess";

const pages = ["Enter Mobile Or Email", "Enter OTP", "Reset Password"];

const initialValues = {
  me: "",
  country: countries[103],
  otp: "",
  password: "",
  confirmPassword: "",
};

const mobileEmailValidation = yup.object({
  me: yup.string().required("Required"),
});

const otpValidation = yup.object({
  otp: yup.string().required("Required"),
});

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

const passwordValidation = yup.object({
  password: yup
    .string("Enter your password")
    .matches(
      passwordRegex,
      "Minimum 6 characters, at least 1 uppercase letter, at least 1 lowercase letter & at least 1 number required"
    )
    .required("Required"),

  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const validationArray = [
  mobileEmailValidation,
  otpValidation,
  passwordValidation,
];

export default function ForgotPassword() {
  const [currentStep, setCurrentStep] = useState(0);
  const [otpId, setotpId] = useState("");
  const [userId, setuserId] = useState("");

  async function getGeoInfo() {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      if (response) {
        const index = countries
          .map((object) => object.code)
          .indexOf(response.data.country_code);
        formik.setFieldValue("country", countries[index]);
      }
    } catch (err) {
      if (err) formik.setFieldValue("country", countries[103]);
    }
  }

  useEffect(() => {
    getGeoInfo();
  }, []);

  const dispatch = useDispatch();

  // const isLastPage = currentStep === pages.length - 1;
  const backStep = (step) => {
    setCurrentStep(step - 1);
  };

  function getEmailOrMobileObj(mobileOrEmail, code) {
    const isText = isNaN(mobileOrEmail);
    if (isText) {
      return {
        email: mobileOrEmail,
        service: "password",
      };
    } else {
      return {
        mobile: mobileOrEmail,
        countryCode: code,
        service: "password",
      };
    }
  }

  const onSubmit = async (values, actions) => {
    const { me, country, otp, password, confirmPassword } = values;

    if (currentStep === 0) {
      const formData = {
        user: getEmailOrMobileObj(me, country.phone),
      };
      try {
        const response = await api.post("/auth/common/otp", formData);
        if (response) {
          const { data } = response.data;
          const { token, _id } = data;
          setAuthToken(token);
          setotpId(_id);
          actions.setTouched({});
          actions.setSubmitting(false);
          setCurrentStep(currentStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("me", err.response?.data.message);
        }
      }
    }
    if (currentStep === 1) {
      try {
        const formData = {
          _id: otpId,
          otp,
        };
        const response = await api.post("/auth/reset/otp", formData);

        if (response) {
          const { token, user } = response.data.data;
          setAuthToken(token);
          setuserId(user?._id);
          actions.setTouched({});
          actions.setSubmitting(false);
          setCurrentStep(currentStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("otp", err.response?.data.message);
        }
      }
    }
    if (currentStep === 2) {
      try {
        const formData = {
          user: {
            password,
            confirmPassword,
          },
        };
        const url = `/auth/reset/users/${userId}`;
        const response = await api.patch(url, formData);
        const { data } = response.data;
        const { token } = data;
        setAuthToken(token);
        if (response) {
          actions.setTouched({});
          actions.setSubmitting(false);
          setCurrentStep(currentStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("confirmPassword", err.response?.data.message);
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: { initialValues },
    validationSchema: validationArray[currentStep],
    onSubmit: onSubmit,
  });

  function renderPageContent(step) {
    switch (step) {
      case 0:
        return <EmailMobile formik={formik} />;
      case 1:
        return (
          <MobileEmailOtp
            backStep={backStep}
            formik={formik}
            currentStep={currentStep}
          />
        );
      case 2:
        return <NewPassword formik={formik} />;

      default:
        return <div>Page Not Found</div>;
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "block",
          width: 500,
          // minHeight: 520,
          // height: 520,
          backgroundColor: "#fff",
          boxShadow: "0px 2px 30px #ccc6",
          margin: "auto",
          marginTop: "7%",
          overflow: "hidden",
          padding: 4,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            "& > :not(style)": {
              mr: 2,
              fontSize: "10",
            },
          }}
        >
          <DDLogo inheritViewBox />
          <Typography variant="h6">Daily Doc</Typography>
        </Box>
        {currentStep === pages.length ? null : (
          <Box
            sx={{
              marginBottom: 4,
              marginTop: 2,
            }}
          >
            <Typography variant="h6">Forgot Password</Typography>
          </Box>
        )}

        <Box
          sx={{
            marginBottom: 8,
            marginTop: 6,
          }}
        >
          {currentStep === pages.length ? (
            <PasswordSuccess
              msg="Password Changed successfully. Please Login"
              passwordChanged={true}
            />
          ) : (
            <form onSubmit={formik.handleSubmit} autoComplete="off">
              {renderPageContent(currentStep)}
              <Box
                sx={{
                  display: "flex",
                  marginTop: 3,
                  marginBotton: 3,
                  gap: 2,
                }}
              >
                <Box sx={{ width: "100%", position: "relative" }}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    fullWidth
                    disabled={formik.isSubmitting}
                  >
                    Next
                  </Button>
                  {formik.isSubmitting && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                        zIndex: 1,
                      }}
                    />
                  )}
                </Box>
              </Box>
            </form>
          )}
        </Box>
      </Box>
    </>
  );
}
