import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";

import InstructionItem from "./InstructionItem";
import { welcomeStepsData } from "./stepsUtil";

export default function GettingStarted() {
  return (
    <Card sx={{ my: 2 }}>
      <CardHeader
        avatar={<DirectionsRunIcon />}
        title="Get started. It's onetime setup."
        subheader="Refer to the following steps to get started."
      />
      <CardContent>
        <List sx={{ maxWidth: 600 }}>
          {welcomeStepsData.map((step, indx) => (
            <InstructionItem
              key={step.title}
              number={indx + 1}
              title={step.title}
              subtitle={step.subtitle}
              steps={step.steps}
            />
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
