import { BEDS_LOADED } from './bedsTypes';
const initialState = {
  isLoading: true,
  isError: false,
  beds: [],
};

export default function bedsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case BEDS_LOADED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        beds: payload,
      };

    default:
      return {
        ...state,
      };
  }
}
