import Container from "@mui/material/Container";
import PageHeading from "../../components/reusables/PageHeading";
import FeedbackForm from "../../components/feedback/FeedbackForm";

function FeedbackPage() {
  return (
    <>
      <Container maxWidth="lg">
        <PageHeading heading="Feedback" />
        <FeedbackForm />
      </Container>
    </>
  );
}

export default FeedbackPage;
