import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';

import ZeroPage from './ZeroPage';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import FourthPage from './FourthPage';

const mobileValidation = yup.object().shape({
  mobile: yup.string('Enter your mobile').required('Mobile is required'),
  mobileOtp: yup.string().when('mobile', {
    is: true,
    then: yup.string().required('Must enter OTP'),
  }),
});

const emailValidation = yup.object().shape({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  emailOtp: yup.string().when('email', {
    is: true,
    then: yup.string().required('Must enter OTP'),
  }),
});

const passwordValidation = yup.object().shape({
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const hospitalValidation = yup.object().shape({
  firstName: yup.string('Enter FirstName Name').required('Required'),
  lastName: yup.string('Enter LastName Name').required('Required'),
  hospitalName: yup.string('Enter Hospital Name').required('Required'),

  country: yup.string('Enter Country Name').required('Required'),
  state: yup.string('Enter State Name').required('Required'),
  city: yup.string('Enter City Name').required('Required'),
});

const validationSchema = [
  mobileValidation,
  emailValidation,
  passwordValidation,
  hospitalValidation,
];

const MuiFormik = () => {
  const [currentStep, setcurrentStep] = useState(0);
  const [openOtp, setopenOtp] = useState(false);

  const formik = useFormik({
    initialValues: {
      code: null,
      mobile: '',
      mobileOtp: '',
      email: '',
      emailOtp: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      hospitalName: '',
      country: '',
      state: '',
      city: '',
      personName: [],
    },
    validationSchema: validationSchema[currentStep],
    onSubmit: (values, actions) => {
      console.log(values);

      if (currentStep === 0) {
        setopenOtp(true);
        actions.setTouched({});
        actions.setSubmitting(false);
        if (values.mobileOtp !== '') {
          alert(JSON.stringify(values, null, 2));
          setcurrentStep(currentStep + 1);
          setopenOtp(false);
        }
      }

      if (currentStep === 1) {
        setopenOtp(true);
        actions.setTouched({});
        actions.setSubmitting(false);
        if (values.emailOtp !== '') {
          alert(JSON.stringify(values, null, 2));
          setcurrentStep(currentStep + 1);
          setopenOtp(false);
        }
      }

      if (currentStep === 2) {
        actions.setTouched({});
        actions.setSubmitting(false);
        alert(JSON.stringify(values, null, 2));
        setcurrentStep(currentStep + 1);
      }
      if (currentStep === 3) {
        actions.setTouched({});
        actions.setSubmitting(false);
        alert(JSON.stringify(values, null, 2));
        setcurrentStep(currentStep + 1);
      }

      if (currentStep === 4) {
        actions.setTouched({});
        actions.setSubmitting(false);
        alert(JSON.stringify(values, null, 2));
        setcurrentStep(currentStep + 1);
      }
      // onSubmit ends
    },
  });

  // function to show part of the form based on the current step
  function renderStepContent(step) {
    switch (step) {
      case 0:
        return <ZeroPage openOtp={openOtp} formik={formik} />;

      case 1:
        return <FirstPage openOtp={openOtp} formik={formik} />;

      case 2:
        return <SecondPage formik={formik} />;
      case 3:
        return <ThirdPage formik={formik} />;
      case 4:
        return <FourthPage formik={formik} />;

      default:
        return null;
    }
  }

  return (
    <Box sx={{ maxWidth: 500, margin: '0 auto' }}>
      <form onSubmit={formik.handleSubmit}>
        {renderStepContent(currentStep)}
        <Button color='primary' variant='contained' fullWidth type='submit'>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default MuiFormik;
