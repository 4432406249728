import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useLocation } from "react-router-dom";

const menuList = [
  { label: "Profile", path: "/dashboard/account" },
  { label: "Add Admin", path: "/dashboard/account/add-admin" },
  { label: "Add Hospitals", path: "/dashboard/account/add-hospitals" },
  { label: "Reset Password", path: "/dashboard/account/change-password" },
  { label: "Security", path: "/dashboard/account/security" },
];

export default function AccountMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Card
      sx={{
        width: 280,
        paddingRight: 6,
        marginTop: 2,
        // height: 300,
      }}
    >
      <List>
        {menuList.map((item) => (
          <ListItem
            key={item.label}
            button
            sx={{
              py: 0.5,
              px: 2,
              borderRadius: 0.5,
              ...(location.pathname === item.path && {
                // backgroundColor: 'background.default',
                backgroundColor: "#bdbdbd",
                // opacity: 0.9,
                color: "#F9FAFC",
              }),
              "&:hover": {
                // backgroundColor: 'background.default',
                backgroundColor: "#bdbdbd",

                color: "#F9FAFC",
              },
            }}
            disablePadding
            onClick={() => navigate(item.path)}
          >
            <ListItemText
              primaryTypographyProps={{
                color: "inherit",
                fontWeight: "medium",
                variant: "body2",
              }}
              primary={item.label}
            />
          </ListItem>
        ))}
      </List>
    </Card>
  );
}
