import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function PasswordSuccess({ msg, passwordChanged }) {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 6,
        }}
      >
        <CheckCircleIcon fontSize="large" color="secondary" />
        <Typography ml={2} component={"span"}>
          {msg}
        </Typography>
      </Box>
      {passwordChanged && (
        <Button
          onClick={() => navigate("/login")}
          variant="outlined"
          color="primary"
        >
          Login
        </Button>
      )}
    </>
  );
}
