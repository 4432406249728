import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import api from "../../utilities/api";
import { loadCityListAction } from "../../redux/list/listAction";
import { showSnackbarAction } from "../../redux/alert/alertAction";

export default function CityAutocomplete({ formik, stateId }) {
  const cityList = useSelector((state) => state.list.cityList);
  const dispatch = useDispatch();

  const loadCityList = async (stateId) => {
    const url = `locations/cities?stateId=${stateId}`;
    try {
      const response = await api.get(url);
      if (response) {
        dispatch(loadCityListAction(response.data.data.cities));
      }
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  useEffect(() => {
    loadCityList(stateId);
  }, [stateId]);

  return (
    <>
      <Autocomplete
        options={cityList}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        id="city"
        value={formik.values.city}
        onChange={(event, newValue) => {
          formik.setFieldValue("city", newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="City"
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        )}
      />
    </>
  );
}
