import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import FormControl from "@mui/material/FormControl";

import { countries } from "../../utilities/countries";

const BoxElement = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginTop: 30,
  marginBottom: 30,
  ".MuiTextField-root": { marginTop: "0" },
}));

export default function EmailMobile(props) {
  // bring formik state to this formik component
  const { formik } = props;
  //  know if the user is typing mobile
  //  Then render country select field
  const isText = isNaN(formik.values.me);

  return (
    <>
      <Typography>
        Enter your registered email or mobile number to change your Daily Doc
        account password
      </Typography>
      <BoxElement>
        <TextField
          sx={{
            transitionProperty: "width",
            transitionDuration: "4s",
            transitionDelay: "2s",
          }}
          autoComplete="off"
          fullWidth
          id="me"
          name="me"
          label="Email or Mobile"
          value={formik.values.me}
          onChange={formik.handleChange}
          error={formik.touched.me && Boolean(formik.errors.me)}
          helperText={formik.touched.me && formik.errors.me}
        />
        <Autocomplete
          // id='country'
          value={formik.values.country}
          name="country"
          options={countries}
          //   groupBy={(option) => option.label}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          style={{
            display: formik.values.em?.length < 3 || isText ? "none" : null,
            // width: 150,
            // marginRight: 10,
            marginTop: 20,
          }}
          onChange={(event, newValue) => {
            formik.setFieldValue("country", newValue);
          }}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="16"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.label} ({option.code}) +{option.phone}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="off"
              onChange={formik.handleChange}
              margin="normal"
              label="Internation calling code"
              fullWidth
              // variant='standard'
              // required
              value={formik.values?.country}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
            />
          )}
        />
      </BoxElement>
    </>
  );
}
