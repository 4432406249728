import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

export default function EditDepartment({ open, onClose, formik }) {
  return (
    <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onClose}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <DialogTitle>Edit Department</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: 2 }}
            //   size="small"
            fullWidth
            id="department"
            name="department"
            label="Department"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            value={formik.values.department}
            onChange={formik.handleChange}
            error={
              formik.touched.department && Boolean(formik.errors.department)
            }
            helperText={formik.touched.department && formik.errors.department}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Box sx={{ position: "relative" }}>
            <Button
              // variant='contained'
              fullWidth
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
            {formik.isSubmitting && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                  zIndex: 1,
                }}
              />
            )}
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}
