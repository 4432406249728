import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import List from "@mui/material/List";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";

import InstructionItem from "./InstructionItem";
import { addUserStepsData } from "./stepsUtil";
import Button from "@mui/material/Button";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";

import CSVDownloader from "../access-control/CSVDownloader";

export default function AddUserInstructions() {
  return (
    <Card sx={{ my: 2 }}>
      <CardHeader
        avatar={<PermContactCalendarIcon />}
        title="Add user to access control table"
        subheader="Refer to the following steps to get started."
      />

      <CardContent>
        <Button
          sx={{ marginLeft: 1, marginBottom: 3, padding: 0.5 }}
          startIcon={<HelpCenterIcon />}
          color="primary"
        >
          <CSVDownloader />
        </Button>

        <List sx={{ maxWidth: 600 }}>
          {addUserStepsData.map((step, indx) => (
            <InstructionItem
              key={step.title}
              number={indx + 1}
              title={step.title}
              subtitle={step.subtitle}
              steps={step.steps}
            />
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
