import {
  LOAD_STATELIST,
  LOAD_COUNTRYLIST,
  LOAD_CITYLIST,
  REMOVE_COUNTRY_LIST,
  REMOVE_STATE_LIST,
  REMOVE_CITY_LIST,
} from "./listTypes";

export const loadCountryListAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_COUNTRYLIST,
    payload: data,
  });
};

export const loadStateListAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_STATELIST,
    payload: data,
  });
};

export const loadCityListAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_CITYLIST,
    payload: data,
  });
};

export const removeCountryListAction = () => (dispatch) => {
  dispatch({
    type: REMOVE_COUNTRY_LIST,
  });
};

export const removeStateListAction = () => (dispatch) => {
  dispatch({
    type: REMOVE_STATE_LIST,
  });
};

export const removeCityListAction = () => (dispatch) => {
  dispatch({
    type: REMOVE_CITY_LIST,
  });
};
