import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';

const steps = [
  {
    label: 'Mobile',
    description: `Add Mobile.`,
  },

  {
    label: 'Email',
    description: 'Add Email.',
  },

  {
    label: 'Password',
    description: 'Enter Password.',
  },
  {
    label: 'User and Hospital Details',
    description: `Add user details`,
  },

  {
    label: 'Choose Departments ',
    description: `Add Departments`,
  },
  {
    label: 'Add Wards/floors',
    description: `Add Wards or floors`,
  },
  {
    label: 'Add Beds',
    description: `Add Beds`,
  },
];

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const isLastStep = activeStep === steps.length - 1;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Container>
      <Box sx={{ maxWidth: 1920 }}>
        <Stepper activeStep={activeStep} orientation='vertical'>
          <Step>
            <StepLabel> Mobile </StepLabel>
          </Step>
          <Step>
            <StepLabel> Email </StepLabel>
          </Step>
        </Stepper>
      </Box>
    </Container>
  );
}

// <StepLabel
//                 optional={
//                   index === 6 ? (
//                     <Typography variant='caption'>Last step</Typography>
//                   ) : null
//                 }
//               >
//                 {step.label}
//               </StepLabel>
//               <StepContent>
//                 <Typography>{step.description}</Typography>
//                 <Box sx={{ mb: 2 }}>
//                   <div>
//                     <Button
//                       variant='contained'
//                       onClick={handleNext}
//                       sx={{ mt: 1, mr: 1 }}
//                     >
//                       {index === steps.length - 1 ? 'Finish' : 'Continue'}
//                     </Button>
//                     <Button
//                       disabled={index === 0}
//                       onClick={handleBack}
//                       sx={{ mt: 1, mr: 1 }}
//                     >
//                       Back
//                     </Button>
//                   </div>
//                 </Box>
//               </StepContent>

// {activeStep === steps.length && (
//   <Paper square elevation={0} sx={{ p: 3 }}>
//     <Typography>All steps completed - you&apos;re finished</Typography>
//     <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//       Reset
//     </Button>
//   </Paper>
// )}
