import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Box } from "@mui/system";

import SubscriptionCard from "./SubsciptionCard";

const features = [
  { feature: "Data Retention", plan: ["Starter", "Growth", "Enterprise"] },
  { feature: "No limit on users", plan: ["Starter", "Growth", "Enterprise"] },
  {
    feature: "1-1 Secure Messaging",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  {
    feature: "Group Messaging (No limit on number of users in the group)",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  {
    feature: "Messaging on patient channel",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  { feature: "Send Pictures", plan: ["Starter", "Growth", "Enterprise"] },
  { feature: "Send Documents", plan: ["Starter", "Growth", "Enterprise"] },
  { feature: "Send Audio files", plan: ["Starter", "Growth", "Enterprise"] },
  { feature: "Send Video files", plan: ["Starter", "Growth", "Enterprise"] },
  { feature: "Hospital Directory", plan: ["Starter", "Growth", "Enterprise"] },

  {
    feature: "Message sent, deliver and read status",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  {
    feature: "Offline messaging support",
    plan: ["Starter", "Growth", "Enterprise"],
  },

  { feature: "Typing Indicator", plan: ["Starter", "Growth", "Enterprise"] },
  { feature: "Online status", plan: ["Starter", "Growth", "Enterprise"] },
  { feature: "Chat History", plan: ["Starter", "Growth", "Enterprise"] },
  {
    feature: "Add patients to DD app",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  { feature: "Discharge patients", plan: ["Starter", "Growth", "Enterprise"] },
  {
    feature: "Provider patient list",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  {
    feature: "Update patients room",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  {
    feature: "Updated care team",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  {
    feature: "Search doctor or nurse or any provider by patients",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  {
    feature: "Search doctor or nurse or any provider by bed",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  { feature: "Analytics Dashboard", plan: ["Starter", "Growth", "Enterprise"] },
  {
    feature: "Add additional admins",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  {
    feature: "Add subadmin to manage few departments",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  {
    feature: "Add Secretary for each or multiple departments",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  {
    feature: "Rapid onboarding with CSV",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  {
    feature: "Minimal data entry for admins",
    plan: ["Starter", "Growth", "Enterprise"],
  },
  { feature: "Community support", plan: ["Starter", "Growth", "Enterprise"] },
  { feature: "Support by email", plan: ["Starter", "Growth", "Enterprise"] },
  {
    feature: "24x7 Great Support including phone",
    plan: ["Growth", "Enterprise"],
  },
  // { feature: "Oncall schedule", plan: ["Growth", "Enterprise"] },
  // { feature: "Physcian schedule", plan: ["Growth", "Enterprise"] },
  // { feature: "Nursing schedule", plan: ["Growth", "Enterprise"] },
  // { feature: "All other staff schedule", plan: ["Growth", "Enterprise"] },
  // { feature: "Live care team", plan: ["Growth", "Enterprise"] },
  // { feature: "Discharge planning", plan: ["Growth", "Enterprise"] },
  { feature: "Dedicated Server", plan: ["Enterprise"] },
  { feature: "Host own your own premises", plan: ["Enterprise"] },
];

const plans = [
  {
    title: "Starter",
    price: 0,
    subtitle: "Good for small hospitals",
  },
  // {
  //   title: "Growth",
  //   price: 95,
  //   subtitle: "Good for large hospitals",
  // },
  {
    title: "Enterprise",
    price: 195,
    subtitle: "Good as enterprise solution",
  },
];
const Subscription = () => {
  const activePlan = "Starter";

  return (
    <Stack sx={{ textAlign: "center", marginTop: 4 }}>
      <Typography variant="h3">Upgrade today. Boost your service!</Typography>
      <Typography variant="body1" sx={{ color: "gray", marginTop: 1 }}>
        We are offering starter plan for free for limited time.
      </Typography>
      <Box sx={{ margin: 8, flexGrow: 1 }}>
        <Grid container spacing={{ xs: 5 }}>
          {plans.map((item, idx) => (
            <Grid item xs={12} md={6} key={idx}>
              <SubscriptionCard
                plan={item}
                activePlan={activePlan}
                features={features}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Stack>
  );
};

export default Subscription;
