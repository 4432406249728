import HospitalTable from "../../components/add-hosppital/HospitalTable";
const MyHospitalsPage = () => {
  return (
    <>
      <HospitalTable />
    </>
  );
};

export default MyHospitalsPage;
