import Container from "@mui/material/Container";
import RegisterAdmin from "../../components/register-admin/RegisterAdmin";

export default function RegisterAdminPage() {
  return (
    <Container>
      <RegisterAdmin />
    </Container>
  );
}
