import { useState } from "react";
import { Box, InputAdornment, Stack, TextField } from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const RegisterAdminDetails = ({ formik }) => {
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setshowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setshowConfirmPassword(!showConfirmPassword);

  return (
    <Box>
      <Stack spacing={4}>
        <TextField
          fullWidth
          id="firstName"
          name="firstName"
          label="First Name"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          fullWidth
          id="lastName"
          name="lastName"
          label="Last Name"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          type={showPassword ? "text" : "password"}
          label="Enter password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" onClick={handleClickShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </InputAdornment>
            ),
          }}
          autoComplete="off"
          autoCapitalize="off"
        />
        <TextField
          fullWidth
          id="confirmPassword"
          name="confirmPassword"
          type={showConfirmPassword ? "text" : "password"}
          label="Confirm password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          InputProps={{
            endAdornment: (
              <InputAdornment
                aria-label="toggle password visibility"
                position="end"
                onClick={handleClickShowConfirmPassword}
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </InputAdornment>
            ),
          }}
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          autoComplete="off"
          autoCapitalize="off"
        />
      </Stack>
    </Box>
  );
};

export default RegisterAdminDetails;
