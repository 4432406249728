import { combineReducers } from "redux";
import authReducer from "./auth/authReducer";
import onboardingReducer from "./onboarding/onboardingReducer";
import accessControlReducer from "./acess-control/AccessControlReducer";
import employeesReducer from "./employees/employeesReducer";
import wardsReducer from "./wards/wardsRducer";
import departmentsReducer from "./departments/departmentsReducer";
import bedsReducer from "./beds/bedsReducer";
import adminsReducer from "./admins/adminReducer";
import listReducer from "./list/listReducer";
import adminHospitalsReducer from "./admin-hospitals/hospitalsReducer";
import alertReducer from "./alert/alertReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  onboarding: onboardingReducer,
  accessControl: accessControlReducer,
  employees: employeesReducer,
  wards: wardsReducer,
  departments: departmentsReducer,
  beds: bedsReducer,
  admins: adminsReducer,
  list: listReducer,
  adminHospitals: adminHospitalsReducer,
  snackbar: alertReducer,
});

export default rootReducer;
