import Schedules from "../../components/schedule/Schedules";

function SchedulesPage() {
  return (
    <div>
      <Schedules />
    </div>
  );
}

export default SchedulesPage;
