import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';

function EmailPage(props) {
  const { formik } = props;

  return (
    <>
      <Stack spacing={4}>
        <Box
          sx={{
            border: '1px solid #E6E8F0',
            height: 56,
            borderRadius: 1,
            backgroundColor: 'background.default',
            display: 'flex',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <Typography sx={{ flexGrow: 1 }}>{formik?.values?.phone}</Typography>
          <DoneIcon color='primary' />
        </Box>
        <TextField
          fullWidth
          id='email'
          name='email'
          label='Email'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Stack>
    </>
  );
}

export default EmailPage;
