import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Button, Typography, Box } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";

import EditAdminForm from "./EditAdminForm";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { loadAllAdminsAction } from "../../redux/admins/adminAction";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import api from "../../utilities/api";

const CurrentAdmins = () => {
  const [editData, setEditData] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const currentAdmins = useSelector((state) => state.admins.allAdmins);
  const hospital = useSelector((state) => state.auth.hospital);
  const dispatch = useDispatch();

  const handleClose = () => {
    setShowEdit(false);
  };

  const requestToken = async (adminId) => {
    try {
      const response = await api.post(
        `/hospitals/${hospital._id}/access/users/${adminId}`
      );
      if (response) {
        dispatch(showSnackbarAction(response.data.message, "success"));
        console.log(response);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };

  const getCurrentAdmins = async (hospitalId) => {
    try {
      let response = await api.get(`hospitals/${hospitalId}/access`);
      // console.log("current admins res", response.data);
      dispatch(loadAllAdminsAction(response.data.data.users));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };

  const handleDelete = async (adminId) => {
    try {
      let response = await api.delete(
        `hospitals/${hospital._id}/access/users/${adminId}`
      );
      dispatch(loadAllAdminsAction(response.data.data.users));
      dispatch(showSnackbarAction(response.data.message, "success"));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };

  useEffect(() => {
    getCurrentAdmins(hospital?._id);
  }, [hospital]);

  const resendRequest = useCallback(
    (row) => () => {
      requestToken(row._id);
    },
    []
  );

  const editRowData = useCallback(
    (row) => () => {
      setEditData(row);
      setShowEdit(true);
    },
    []
  );
  const deleteUser = useCallback(
    (row) => () => {
      handleDelete(row._id);
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        field: "fullName",
        headerName: "Name",
        minWidth: 180,
        type: "string",
        flex: 2,
        renderCell: (data) => {
          return data.row.webOnboarded ? data.row.fullName : "Pending";
        },
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 180,
        type: "string",
        flex: 2,
      },
      {
        field: "mobile",
        headerName: "Phone",
        minWidth: 110,
        type: "string",
        flex: 1,
      },
      {
        field: "role",
        headerName: "Role",
        minWidth: 80,
        type: "string",
        flex: 1,
      },
      {
        field: "departments",
        headerName: "Departments Assigned",
        minWidth: 180,
        type: "string",
        flex: 2,
        renderCell: ({ row }) => {
          return row?.departments ? (
            <Box>
              {row.departments.map((item) => (
                <Typography key={item.name}>{item.name}</Typography>
              ))}
            </Box>
          ) : (
            "Hospital"
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        minWidth: 60,
        type: "string",
        flex: 1,
        renderCell: (data) => {
          return data.row.webOnboarded ? "Registered" : "Pending";
        },
      },

      {
        field: "request",
        headerName: "Request",
        type: "actions",
        minWidth: 60,
        flex: 1,
        renderCell: (data) => {
          return data.row.webOnboarded ? (
            <span style={{ color: "blue" }}>Active</span>
          ) : (
            <Button
              onClick={() => requestToken(data.row._id)}
              style={{ color: "red", cursor: "pointer" }}
            >
              Resend request
            </Button>
          );
        },
      },

      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        minWidth: 60,
        flex: 1,
        getActions: (data) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={editRowData(data.row)}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={deleteUser(data.row)}
            />,
          ];
        },
      },
    ],
    [resendRequest, editRowData, deleteUser]
  );

  return (
    <Paper style={{ height: 600, width: "100%", marginTop: 18, padding: 32 }}>
      <DataGrid
        sx={{
          // mx: 5,
          border: 2,
          borderColor: "divider",
          "& .MuiDataGrid-cell": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
        // getRowHeight={() => "auto"}
        columns={columns}
        rows={currentAdmins}
        getRowHeight={({ id, densityFactor, model }) => {
          // console.log("prop", model);
          if (model?.departments?.length > 0) {
            return model?.departments?.length * 30 * densityFactor;
          }
          return null;
        }}
      />
      {showEdit && (
        <EditAdminForm
          editData={editData}
          showEdit={showEdit}
          handleClose={handleClose}
        />
      )}
    </Paper>
  );
};

export default CurrentAdmins;
