import {
  AUTH_ERROR,
  USER_LOADED,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  HSPITAL_PROFILES_LOADED,
} from "./authTypes";

import api from "../../utilities/api";

// loaduser
// export const loadUserAction = (data) => async (dispatch) => {
//   dispatch({
//     type: USER_LOADED,
//     payload: data,
//   });
// };

export const loadUserAction = () => async (dispatch) => {
  try {
    const response = await api.get("/hospitals/user");
    if (response)
      dispatch({
        type: USER_LOADED,
        payload: response.data.data,
      });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// login

// export const loginAction = (loginSuccess) => (dispatch) => {
//   if (loginSuccess) {
//     dispatch(loadUserAction());
//     dispatch({
//       type: LOGIN_SUCCESS,
//     });
//   } else {
//     dispatch({
//       type: LOGIN_FAILURE,
//     });
//   }
// };

export const loadHospitalProfilesAction = (data) => (dispatch) => {
  dispatch({
    type: HSPITAL_PROFILES_LOADED,
    payload: data,
  });
};

export const loginSuccessAction = (data) => (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: data,
  });
  // dispatch(loadUserAction());
};

export const loginFailureAction = () => (dispatch) => {
  dispatch({
    type: LOGIN_FAILURE,
  });
};

// logout
export const logoutAction = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
