import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/system";
import { getAccessControlData } from "./utilAccessControl";
import Paper from "@mui/material/Paper";
import DialogAlert from "../alerts/DialogAlert";
import Tooltip from "@mui/material/Tooltip";
import api from "../../utilities/api";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import { loadAccessControlDataAction } from "../../redux/acess-control/AccessControlAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  px: 4,
  py: 8,
};

const StyledForm = styled("form")({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  // '.MuiTextField-root': { marginTop: '0' },
  // '.MuiButton-root': { float: 'right' },
  ".MuiAutocomplete-root": { width: 200, marginRight: 16 },
  ".MuiTextField-root": { marginTop: 0, marginBottom: 0 },
});

//  for formik

const validationSchema = yup.object().shape({
  countryCode: yup
    .string("Provide country code for mobile")
    .required("Required"),
  mobile: yup.string("Provide mobile number").required("Required"),
});

function AccessControlTable() {
  const [isEditDialogOpen, setisEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setisDeleteDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState([]);
  const [rowToDelete, setRowToDelete] = useState([]);
  const [deleteErr, setdeleteErr] = useState("");
  const [editErr, setEditErr] = useState("");

  const user = useSelector((state) => state.auth.user);
  const hospital = useSelector((state) => state.auth.hospital);
  const { accessControlData } = useSelector((state) => state.accessControl);
  const dispatch = useDispatch();

  // for formik set up
  const onFormSubmit = async (values, actions) => {
    console.log(values);
    const formData = {
      user: {
        mobile: values.mobile,
        countryCode: values.countryCode,
      },
    };
    try {
      const response = await api.patch(
        `hospitals/${hospital?._id}/users/${user?._id}`,
        formData
      );
      console.log("edit ac res", response.data);
      dispatch(loadAccessControlDataAction(response.data.data.users));
      closeEditDialog();
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  const deleteUser = async () => {
    closeDeleteDialog();
    return;
    try {
      const response = await api.delete(
        `hospitals/${hospital?._id}/users/${user?._id}`
      );
      console.log("delete ac res", response.data);
      dispatch(loadAccessControlDataAction(response.data.data.users));
      closeEditDialog();
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  const formik = useFormik({
    initialValues: {
      employeeId: rowToEdit.id || "",
      countryCode: rowToEdit.countryCode || "",
      mobile: rowToEdit.mobile || "",
    },
    validationSchema: validationSchema,
    onSubmit: onFormSubmit,
    enableReinitialize: true,
  });

  // edit row
  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      setisEditDialogOpen(true);
    },
    []
  );

  // open delete dialogalert
  const openDeleteDialog = useCallback(
    (id, row) => () => {
      setRowToDelete(row);
      setisDeleteDialogOpen(true);
    },
    []
  );

  // close dialoge
  const closeEditDialog = () => {
    setisEditDialogOpen(false);
    setRowToEdit([]);
  };
  const closeDeleteDialog = () => {
    setisDeleteDialogOpen(false);
    setRowToDelete([]);
  };

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "DD-ID",
        description: "Daily Doc ID",
        type: "string",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "countryCode",
        headerName: "Country Code",
        description: "Country Code for mobiles",
        type: "string",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "mobile",
        headerName: "Mobile",
        description: "Employee mobile number",
        type: "string",
        minWidth: 150,
        flex: 1,
      },
      {
        field: "registered",
        headerName: "Registered",
        description: "Employee has onboarded in the Daily Doc app",
        type: "boolean",
        minWidth: 150,
      },

      {
        field: "actions",
        headerName: "Actions",
        description: "Modify table",
        type: "actions",
        minWidth: 200,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete mobile">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit mobile">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    [openDeleteDialog, editRow]
  );

  useEffect(() => {
    getAccessControlData(hospital._id);
    return () => {};
  }, [hospital]);

  return (
    <Paper
      sx={{
        height: 600,
        width: "100%",
        marginTop: 2,
        // boxShadow: 8,
        padding: 4,
      }}
    >
      <DataGrid
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        columns={columns}
        rows={accessControlData}
        sx={{
          border: 2,
          borderColor: "divider",
          "& .MuiDataGrid-cell": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: 2,
            borderBottomColor: "divider",
          },
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
      <Modal
        open={isEditDialogOpen}
        onClose={closeEditDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StyledForm className="employee-form" onSubmit={formik.handleSubmit}>
            <TextField
              sx={{ marginRight: 2 }}
              size="small"
              // fullWidth
              id="countryCode"
              name="countryCode"
              label="Country Code"
              value={formik.values.countryCode}
              onChange={formik.handleChange}
              error={
                formik.touched.countryCode && Boolean(formik.errors.countryCode)
              }
              helperText={
                formik.touched.countryCode && formik.errors.countryCode
              }
            />
            <TextField
              sx={{ marginRight: 2 }}
              size="small"
              // fullWidth
              id="mobile"
              name="mobile"
              label="Mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
            />
            <Button
              type="submit"
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </StyledForm>
        </Box>
      </Modal>
      <DialogAlert
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        handleYes={deleteUser}
        title={`Delete mobile ${rowToDelete?.mobile}`}
        msg={
          deleteErr ||
          `Are you sure you want to delete mobile: ${rowToDelete?.mobile}?`
        }
      />
    </Paper>
  );
}

export default AccessControlTable;
