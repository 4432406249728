import { SHOW_SNACKBAR, CLOSE_SNACKBAR } from "./alertTypes";
const initialState = {
  snackbarOpen: false,
  snackbarType: "success",
  snackbarMessage: "",
};

function alertReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbarType: payload.snackbarType,
        snackbarMessage: payload.message,
        snackbarOpen: true,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbarOpen: false,
        // snackbarMessage: "",
        // snackbarType: "",
      };
    default:
      return state;
  }
}
export default alertReducer;
