import { EMPLOYEES_LOADED, LOAD_DEFAULT_EMPLOYEE_DATA } from './employeesTypes';

export const loadEmployeesAction = (data) => (dispatch) => {
  dispatch({
    type: EMPLOYEES_LOADED,
    payload: data,
  });
};

export const loadDefaultEmployeeDataAction = (data) => (dispatch) => {
  dispatch({
    type: LOAD_DEFAULT_EMPLOYEE_DATA,
    payload: data,
  });
};
