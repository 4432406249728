import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import HospitalDetailsPage from "./HospitalDetailsPage";
import ObWardsPage from "../onboarding/ObWardsPage";
import ObBedsPage from "../onboarding/ObBedsPage";
import api from "../../utilities/api";
import { countryList } from "../../utilities/countryList";

import CircularProgress from "@mui/material/CircularProgress";

import {
  loadStateListAction,
  loadCityListAction,
  removeStateListAction,
  removeCityListAction,
} from "../../redux/list/listAction";

import { showSnackbarAction } from "../../redux/alert/alertAction";
import { loadAllAdminsHospitalsAction } from "../../redux/admin-hospitals/hospitalsActions";

const PaperElement = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  width: 1000,
  padding: 50,
  ".stepper-form": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: 'red',
    justifyContent: "space-between",
  },
}));

const steps = ["Hospital Details", "Add Wards", "Add Beds"];
const headings = ["Hospital Details", "Add Wards", "Add Beds"];

const hospitalDetailsValidation = yup.object().shape({
  hospitalName: yup.string("Enter Hospital Name").required("Required"),
  hospitalShortform: yup
    .string("Enter Hospital Name Shortform")
    .required("Required"),
  country: yup
    .object()
    .shape({
      label: yup.string("Enter Country Name").required("Required"),
    })
    .typeError("Choose a country"),

  state: yup
    .object()
    .shape({
      label: yup.string("Enter state").required("Required"),
    })
    .typeError("Choose a state"),
  city: yup
    .object()
    .shape({
      label: yup.string("Enter City").required("Required"),
    })
    .typeError("Choose a city"),
  departments: yup
    .array()
    .of(yup.object({ name: yup.string().required("Required") }))
    .min(1, "Choose at least one department")
    .compact(),
});

const wardValidation = yup.object().shape({
  wards: yup
    .array()
    .of(yup.object({ name: yup.string().required("Required") }))
    .min(1, "Add at least one ward from your hospital.")
    .compact(),
});

const bedValidation = yup.object().shape({
  beds: yup
    .array()
    .of(yup.object({ bedName: yup.string().required("Required") }))
    .min(1, "Add at least one bed from your hospital.")
    .compact(),
});

const validationSchema = [
  hospitalDetailsValidation,
  wardValidation,
  bedValidation,
];

const EditNewHospital = ({ open, onClose, obHospital }) => {
  const [initialCountry, setInitialCountry] = useState(null);
  const [initialState, setInitialState] = useState(null);
  const [initialCity, setInitialCity] = useState(null);
  const [hospitalId, setHospitalId] = useState(null);

  const [activeStep, setactiveStep] = useState(
    obHospital?.hospital?.completedStep
  );
  const [activeHeading, setactiveHeading] = useState(
    obHospital?.hospital?.completedStep
  );

  const isLastStep = activeStep === steps.length - 1;

  // for calling action creator
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //  pull redux state

  const hospital = useSelector((state) => state.auth.hospital);
  const user = useSelector((state) => state.auth.user);
  const { isStateListLoading, isCityListLoading } = useSelector(
    (state) => state.list
  );

  const defaultDepartments = useSelector(
    (state) => state.onboarding.defaultDepartments
  );

  // function for going to the back page.
  function handleBack(step, heading) {
    if (step > 0 && heading > 0) {
      setactiveStep(step - 1);
      setactiveHeading(heading - 1);
    }
  }

  const loadCountryStateCity = async () => {
    const country = countryList.find(
      (c) => c.value === obHospital?.hospital?.country
    );
    setInitialCountry(country);
    loadStateList(country?.countryId);
  };

  const loadStateList = async (countryId) => {
    try {
      const response = await api.get(`locations/states?countryId=${countryId}`);
      const state = response.data.data.states?.find(
        (s) => s.value === obHospital?.hospital?.state
      );
      setInitialState(state);
      dispatch(loadStateListAction(response.data.data.states));
      loadCityList(state?.stateId);
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  const loadCityList = async (stateId) => {
    try {
      const response = await api.get(`locations/cities?stateId=${stateId}`);
      setInitialCity(
        response.data.data.cities.find(
          (c) => c.value === obHospital?.hospital?.city
        )
      );
      dispatch(loadCityListAction(response.data.data.cities));
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  const onSubmit = async (values, actions) => {
    console.log("active step", activeStep);
    if (activeStep === 0) {
      const formData = {
        hospitalDetails: {
          userId: user?._id,
          loggedInHospitalId: hospital?._id,
          _id: hospitalId ? hospitalId : "",
          name: values.hospitalName,
          shortName: values.hospitalShortform,
          country: values.country.value,
          state: values.state.value,
          city: values.city.value,
          departments: values.departments,
        },
      };

      try {
        const response = await api.post(
          "/onboarding/hospitals/step1",
          formData
        );
        console.log("response step0", response);
        setHospitalId(response.data.data.savedHospital._id);
        dispatch(showSnackbarAction(response.data.message, "success"));
        actions.setTouched({});
        actions.setSubmitting(false);
        setactiveStep(activeStep + 1);
        setactiveHeading(activeHeading + 1);
      } catch (err) {
        dispatch(showSnackbarAction(err.response.data.message, "error"));
      }
    }
    if (activeStep === 1) {
      let { wards } = values;
      const formData = {
        userId: user?._id,
        loggedInHospitalId: hospital?._id,
        hospitalId: hospitalId,
        wards: wards,
      };

      try {
        const response = await api.post(
          "/onboarding/hospitals/step2",
          formData
        );
        console.log(`API call step ${activeStep}: `, response);
        if (response) {
          actions.setFieldValue("wards", response.data.data.savedWards);

          // update beds arry
          if (values.beds?.length > 0) {
            const newBeds = values.beds?.map((item) => {
              const updatedBed = response.data.data.savedWards.find(
                (i2) => i2.uid === item.wardUid
              );
              return updatedBed
                ? {
                    ...item,
                    wardId: updatedBed?._id,
                    wardName: updatedBed.name,
                  }
                : item;
            });
            actions.setFieldValue("beds", newBeds);
          }
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          dispatch(showSnackbarAction(err.response.data.message, "error"));
        }
      }
    }

    if (activeStep === 2) {
      let { beds } = values;
      const formData = {
        userId: user?._id,
        loggedInHospitalId: hospital?._id,
        hospitalId: hospitalId ? hospitalId : "",
        wardBeds: beds,
      };

      try {
        const response = await api.post(
          "/onboarding/hospitals/step3",
          formData
        );
        console.log(`API call step ${activeStep}: `, response);
        dispatch(
          loadAllAdminsHospitalsAction(response.data.data.hospitalsList)
        );
        dispatch(showSnackbarAction(response.data.message, "success"));
        onClose();
      } catch (err) {
        if (err) {
          dispatch(showSnackbarAction(err.response.data.message, "error"));
        }
      }
    }
  };

  // onSubmit ends

  const initialValues = {
    hospitalName: obHospital?.hospital?.name || "",
    hospitalShortform: obHospital?.hospital?.shortName || "",
    country: initialCountry || null,
    state: initialState || null,
    city: initialCity || null,
    departments: obHospital?.departments || [],
    ward: "",
    wards: obHospital?.wards || [],
    selectedWard: null,
    bed: "",
    beds: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema[activeStep],
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  const handleExit = () => {
    formik.resetForm({
      values: initialValues,
    });
    // dispatch(resetOnboardingAction());
    // dispatch(logoutAction());
    onClose();
    setactiveStep(0);
    setactiveHeading(0);
    // navigate("/dashboard/hospitals");
  };

  // function to show part of the form based on the current step
  function renderStepContent(step) {
    switch (step) {
      case 0:
        return <HospitalDetailsPage formik={formik} />;
      case 1:
        return <ObWardsPage formik={formik} />;
      case 2:
        return <ObBedsPage formik={formik} />;

      default:
        return null;
    }
  }

  useEffect(() => {
    setHospitalId(obHospital.hospital?._id);
    return () => setHospitalId(null);
  }, [obHospital.hospital?._id]);

  useEffect(() => {
    console.log("UE Called");
    loadCountryStateCity();
    return () => {
      dispatch(removeStateListAction());
      dispatch(removeCityListAction());
    };
  }, []);

  console.log("active step", activeStep);
  console.log("formik", formik);
  console.log("hospital id", hospitalId);
  console.log("obhospital", obHospital);

  return (
    <Dialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
      <Card
        sx={{
          minHeight: 600,
          padding: 6,
        }}
      >
        <CardHeader
          avatar={
            <AccountBalanceIcon sx={{ fontSize: 24 }} color="secondary" />
          }
          title="Complete all steps to register the hospital"
          sx={{ p: 1, m: 0 }}
        />
        <CardContent sx={{ p: 0, mt: 5, mx: 0 }}>
          <Box sx={{ maxWidth: 600, marginBottom: 8 }}>
            <Stepper activeStep={activeHeading} orientation="horizontal">
              {headings.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box sx={{ px: 1 }}>
            <form
              className="stepper-form"
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              <Box>{renderStepContent(activeStep)}</Box>
              <Stack sx={{ marginTop: 8 }} direction="row-reverse" spacing={2}>
                <Button
                  color="primary"
                  variant="contained"
                  // fullWidth
                  disabled={formik.isSubmitting}
                  type="submit"
                  endIcon={<NavigateNextIcon />}
                >
                  {isLastStep ? "Submit" : "Next"}
                </Button>
                {activeStep > 0 && (
                  <Button
                    onClick={() => handleBack(activeStep, activeHeading)}
                    variant="outlined"
                    startIcon={<ArrowBackIcon />}
                  >
                    Back
                  </Button>
                )}
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => handleExit()}
                >
                  Exit
                </Button>
              </Stack>
            </form>
          </Box>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default EditNewHospital;
