import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  HSPITAL_PROFILES_LOADED,
} from "./authTypes";
import setAuthToken from "../../utilities/setAuthToken";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: true,
  user: null,
  hospital: null,
  isAuthenticatedToSeeHospitalProfiles: null,
  hospitalProfiles: [],
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HSPITAL_PROFILES_LOADED:
      return {
        ...state,
        user: payload.hospital.user,
        token: payload.token,
        hospitalProfiles: payload.hospital.userAccounts,
        isAuthenticatedToSeeHospitalProfiles: true,
      };

    case USER_LOADED:
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: payload.token,
        isLoading: false,
        isAuthenticated: true,
        user: payload.hospital.user,
        hospital: payload.hospital,
      };

    case LOGOUT:
    case LOGIN_FAILURE:
    case AUTH_ERROR: {
      // setAuthToken called without token, it will delete token from local storage
      setAuthToken();
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isLoading: false,
        user: null,
        hospital: null,
        isAuthenticatedToSeeHospitalProfiles: null,
        hospitalProfiles: [],
      };
    }

    default:
      return state;
  }
}

export default authReducer;
