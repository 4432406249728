import PropTypes from "prop-types";

import { Stack, TextField, Box } from "@mui/material";

const AdminMobileVerification = ({ formik }) => {
  return (
    <Box>
      <Stack spacing={4}>
        <TextField
          fullWidth
          id="countryCode"
          name="countryCode"
          value={formik.values.countryCode}
          disabled={true}
          label="Country code"
        />
        <TextField
          fullWidth
          id="phone"
          name="phone"
          value={formik.values.mobile}
          disabled={true}
          label="Mobile No"
        />
        <TextField
          fullWidth
          id="otp"
          name="otp"
          label="Enter OTP"
          value={formik.values.otp}
          onChange={formik.handleChange}
          error={formik.touched.otp && Boolean(formik.errors.otp)}
          helperText={formik.touched.otp && formik.errors.otp}
        />
      </Stack>
    </Box>
  );
};

export default AdminMobileVerification;

AdminMobileVerification.propTypes = {
  formik: PropTypes.object.isRequired,
};
