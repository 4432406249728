import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

export default function EditWards({ open, onClose, formik }) {
  return (
    <Dialog fullWidth maxWidth={"xs"} open={open} onClose={onClose}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <DialogTitle>Edit Ward</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: 2 }}
            //   size="small"
            fullWidth
            id="ward"
            name="ward"
            label="Ward"
            autoComplete="off"
            autoCapitalize="off"
            autoCorrect="off"
            value={formik.values.ward}
            onChange={formik.handleChange}
            error={formik.touched.ward && Boolean(formik.errors.ward)}
            helperText={formik.touched.ward && formik.errors.ward}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Box sx={{ position: "relative" }}>
            <Button
              // variant='contained'
              fullWidth
              type="submit"
              disabled={formik.isSubmitting}
            >
              Save
            </Button>
            {formik.isSubmitting && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                  zIndex: 1,
                }}
              />
            )}
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
}
