import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Box from "@mui/system/Box";
import DDLogo from "../icons/ddLogo";

function Notfound() {
  const navigate = useNavigate();
  return (
    <Container>
      <Stack sx={{ textAlign: "center", marginX: 20, marginY: 35 }}>
        <Typography variant="h5">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DDLogo />
            <Typography ml={2} variant="h5" sx={{ color: "#0000D1" }}>
              Daily Doc
            </Typography>
          </div>
        </Typography>
        <Box sx={{ paddingX: 20 }}>
          <Typography variant="h3" mt={8}>
            Page not found
          </Typography>
          <Typography sx={{ color: "gray", mt: 2, mb: 4 }}>
            We are very sorry for the inconvenience.It looks like yor are trying
            to access a page that has been deleted or never even existed.
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            sx={{ height: 50, width: 200 }}
          >
            Back
          </Button>
        </Box>
      </Stack>
    </Container>
  );
}

export default Notfound;
