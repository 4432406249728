import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import { Box, Modal, Stack, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CircularProgress from "@mui/material/CircularProgress";

import {
  loadStateListAction,
  loadCityListAction,
  removeStateListAction,
  removeCityListAction,
} from "../../redux/list/listAction";
import { loadAllAdminsHospitalsAction } from "../../redux/admin-hospitals/hospitalsActions";

import api from "../../utilities/api";
import CountryAutocomplete from "../reusables/CountryAutocomplete";
import StateAutocomplete from "../reusables/StateAutocomplete";
import CityAutocomplete from "../reusables/CityAutocomplete";
import { countryList } from "../../utilities/countryList";
import { showSnackbarAction } from "../../redux/alert/alertAction";

const GridElement = styled(Grid)(({ theme }) => ({
  marginBottom: 32,
  ".MuiTextField-root": { marginTop: "0" },
}));

const validationSchema = yup.object().shape({
  hospitalName: yup.string("Enter Hospital Name").required("Required"),
  hospitalShortform: yup
    .string("Enter Hospital Name Shortform")
    .required("Required"),
  country: yup
    .object()
    .shape({
      label: yup.string("Enter Country").required("Required"),
    })
    .typeError("Choose a country"),

  state: yup
    .object()
    .shape({
      label: yup.string("Enter state").required("Required"),
    })
    .typeError("Choose a state"),

  city: yup
    .object()
    .shape({
      label: yup.string("Enter city").required("Required"),
    })
    .typeError("Choose a city"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  px: 4,
  py: 8,
};

const progressBarStyle = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
};

export default function EditHospital({
  isEditDialogOpen,
  closeEditDialog,
  rowToEdit,
}) {
  const [initialCountry, setInitialCountry] = useState(null);
  const [initialState, setInitialState] = useState(null);
  const [initialCity, setInitialCity] = useState(null);

  const user = useSelector((state) => state.auth.user);
  const { isStateListLoading, isCityListLoading } = useSelector(
    (state) => state.list
  );

  const dispatch = useDispatch();

  const onSubmit = async (values, actions) => {
    const formData = {
      hospitalDetails: {
        name: values.hospitalName,
        shortName: values.hospitalShortform,
        country: values.country.label,
        state: values.state.label,
        city: values.city.label,
      },
    };
    try {
      const response = await api.patch(
        `hospitals/${rowToEdit._id}/users/${user._id}/primaryHospitals`,
        formData
      );
      dispatch(loadAllAdminsHospitalsAction(response.data.data.hospitalsList));
      closeEditDialog();
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };

  const formik = useFormik({
    initialValues: {
      hospitalName: rowToEdit?.name,
      hospitalShortform: rowToEdit?.shortName,
      country: initialCountry || null,
      state: initialState || null,
      city: initialCity || null,
    },
    onSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const loadCountryStateCity = async () => {
    const country = countryList.find((c) => c.value === rowToEdit?.country);
    setInitialCountry(country);
    loadStateList(country?.countryId);
  };

  const loadStateList = async (countryId) => {
    try {
      const response = await api.get(`locations/states?countryId=${countryId}`);
      const state = response.data.data.states?.find(
        (s) => s.value === rowToEdit?.state
      );
      setInitialState(state);
      dispatch(loadStateListAction(response.data.data.states));
      loadCityList(state?.stateId);
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  const loadCityList = async (stateId) => {
    try {
      const response = await api.get(`locations/cities?stateId=${stateId}`);
      setInitialCity(
        response.data.data.cities.find((c) => c.value === rowToEdit?.city)
      );
      dispatch(loadCityListAction(response.data.data.cities));
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  };

  useEffect(() => {
    loadCountryStateCity();
    return () => {
      dispatch(removeStateListAction());
      dispatch(removeCityListAction());
    };
  }, []);

  return (
    <>
      {isStateListLoading || isCityListLoading ? (
        <Box sx={progressBarStyle}>
          <CircularProgress />
        </Box>
      ) : (
        <Modal
          open={isEditDialogOpen}
          onClose={closeEditDialog}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack direction="row">
              <AccountBalanceIcon
                sx={{ fontSize: 24, marginRight: 2 }}
                color="secondary"
              />
              <Typography sx={{ marginBottom: 4 }} variant="h6" component="h2">
                Edit Hospital{" "}
              </Typography>
            </Stack>

            <form onSubmit={formik.handleSubmit}>
              <GridElement
                container
                rowSpacing={4}
                columnSpacing={{ sm: 2, md: 3 }}
              >
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="hospitalName"
                    name="hospitalName"
                    label="Hospital Name"
                    value={formik.values.hospitalName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.hospitalName &&
                      Boolean(formik.errors.hospitalName)
                    }
                    helperText={
                      formik.touched.hospitalName && formik.errors.hospitalName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="hospitalShortform"
                    name="hospitalShortform"
                    label="Hospital Name Shortform"
                    value={formik.values.hospitalShortform}
                    onChange={formik.handleChange}
                    inputProps={{ maxLength: 6 }}
                    error={
                      formik.touched.hospitalShortform &&
                      Boolean(formik.errors.hospitalShortform)
                    }
                    helperText={
                      formik.touched.hospitalShortform &&
                      formik.errors.hospitalShortform
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <CountryAutocomplete formik={formik} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StateAutocomplete
                    formik={formik}
                    countryId={formik.values.country?.countryId}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CityAutocomplete
                    formik={formik}
                    stateId={formik.values.state?.stateId}
                  />
                </Grid>
              </GridElement>
              <Grid container rowSpacing={4} columnSpacing={{ sm: 2, md: 3 }}>
                <Grid item xs={6} sm={6}>
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="outlined"
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Button
                    fullWidth
                    color="error"
                    variant="outlined"
                    // disabled={formik.isSubmitting}
                    onClick={() => closeEditDialog()}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      )}
    </>
  );
}
