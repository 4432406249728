import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: false,
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
    title: {
      display: true,
      text: "Daily Doc data use",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June"];

const data = {
  labels,
  datasets: [
    {
      label: "Messages",
      data: [10, 15, 50, 300, 400, 19],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Picture",
      data: [13, 200, 60, 200, 89, 19],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

export default function DataUseChart() {
  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        padding: 1,
        boxShadow: 8,
      }}
    >
      <CardContent>
        <Line options={options} data={data} width={500} height={280} />
      </CardContent>
    </Card>
  );
}
