import {
  DEPARTMENTS_FETCHED,
  DEFAULT_DEPARTMENTS_FETCHED,
} from './departmentsTypes';
const initialState = {
  isLoading: true,
  isError: false,
  departmentsList: [],
  defaultDepartments: [],
};

export default function departmentsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case DEPARTMENTS_FETCHED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        departmentsList: payload,
      };
    case DEFAULT_DEPARTMENTS_FETCHED:
      return {
        ...state,
        defaultDepartments: payload,
      };

    default:
      return {
        ...state,
      };
  }
}
