import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const SECOND = 1000;
const MINUTE = SECOND * 60;

export default function Timer({ resendOtp }) {
  const expiryMinutes = 2;
  const [deadline, setDeadline] = useState(
    Date.now() + expiryMinutes * 60 * 1000
  );
  const [time, setTime] = useState(expiryMinutes * 60 * 1000);

  useEffect(() => {
    const interval = setInterval(() => setTime(deadline - Date.now()), 1000);
    return () => clearInterval(interval);
  }, [deadline]);

  return (
    <Box
      sx={{
        height: "40px",
        display: "flex",
        alignItems: "center",
        // px: 1,
      }}
    >
      {time < 1000 ? (
        <Button
          type="button"
          variant="text"
          startIcon={<RestartAltIcon />}
          onClick={() => {
            resendOtp();
            setTime(expiryMinutes * 60 * 1000);
            setDeadline(Date.now() + expiryMinutes * 60 * 1000);
          }}
        >
          Resend OTP
        </Button>
      ) : (
        <>
          <WatchLaterIcon
            fontSize="large"
            color="primary"
            // sx={{ color: (theme) => theme.palette.neutral[400] }}
          />
          <Typography ml={2}>
            <span>
              {`${Math.floor((time / MINUTE) % 60)}`.padStart(2, "0")}
            </span>{" "}
            <span>:</span>
            <span>
              {`${Math.floor((time / SECOND) % 60)}`.padStart(2, "0")}
            </span>{" "}
          </Typography>
        </>
      )}
    </Box>
  );
}
