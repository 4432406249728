import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Welcome from "../../components/dashboard/Welcome";
import UserStatusBarChart from "../../components/dashboard/UserStatusBarChart";
import NumberCard from "../../components/dashboard/NumberCard";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import StoreIcon from "@mui/icons-material/Store";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import { showSnackbarAction } from "../../redux/alert/alertAction";

import api from "../../utilities/api";
import DataUseChart from "../../components/dashboard/DataUseChart";
import DbAccessControlTable from "../../components/dashboard/DbAccessControlTable";

function DashboardPage() {
  const [hospitalData, setHospitalData] = useState(null);
  const [userCounts, setUserCounts] = useState(null);

  const dispatch = useDispatch();
  const hospital = useSelector((state) => state.auth.hospital);

  const getHospitalData = async (hospitalId) => {
    try {
      const response = await api.get(`hospitals/${hospitalId}/dashboard`);
      setHospitalData(response.data.data);
    } catch (error) {
      // dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };

  const getUserCountsData = async (hospitalId) => {
    try {
      const response = await api.get(`hospitals/${hospitalId}/userCounts`);
      setUserCounts(response.data.data.usersCount);
    } catch (error) {
      // dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };

  //   const getLineChartData = async (hospitalId) => {
  //     const endDate = new Date().toUTCString();
  //     try {
  //       const response = await api.get(
  //         `hospitals/${hospitalId}/chatAnalytics?startDate=1 Jan 2020&endDate=1 Jan 2024`
  //       );
  //       console.log("Line chart data", response.data.data);
  //     } catch (error) {
  //       dispatch(showSnackbarAction(error.response.data.message, "error"));
  //     }
  //   };

  useEffect(() => {
    getHospitalData(hospital?._id);
    getUserCountsData(hospital?._id);
  }, [hospital?._id]);

  return (
    <Container maxWidth="lg">
      <Box mt={2} mb={6}>
        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 3, md: 4 }}>
          <Grid item sm={12} md={8} sx={{ height: 480 }}>
            <Welcome />
          </Grid>
          <Grid item sm={12} md={4} sx={{ height: 480 }}>
            <UserStatusBarChart userCounts={userCounts} />
          </Grid>
          <Grid item xs={6} sm={3} sx={{ height: 216 }}>
            <NumberCard
              header="Departments"
              num={hospitalData?.departments}
              icon={
                <StoreIcon sx={{ height: 40, width: 40 }} color="secondary" />
              }
              path="departments"
            />
          </Grid>
          <Grid item xs={6} sm={3} sx={{ height: 216 }}>
            <NumberCard
              header="Wards"
              num={hospitalData?.wards}
              icon={
                <VaccinesIcon sx={{ height: 40, width: 40 }} color="warning" />
              }
              path="wards"
            />
          </Grid>
          <Grid item xs={6} sm={3} sx={{ height: 216 }}>
            <NumberCard
              header="Beds"
              num={hospitalData?.beds}
              icon={
                <LocalHotelIcon sx={{ height: 40, width: 40 }} color="error" />
              }
              path="beds"
            />
          </Grid>
          <Grid item xs={6} sm={3} sx={{ height: 216 }}>
            <NumberCard
              header="Registered Users"
              num={hospitalData?.employees}
              icon={
                <PermContactCalendarIcon
                  sx={{ height: 40, width: 40 }}
                  color="primary"
                />
              }
              path="employees"
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ height: 416 }}>
            <DbAccessControlTable />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ height: 416 }}>
            <DataUseChart />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default DashboardPage;
