import * as yup from "yup";

const mobileValidation = yup.object().shape({
  phone: yup.string("Enter your mobile with country code").required("Required"),
});

const mobileOtpValidation = yup.object().shape({
  mobileOtp: yup
    .string("Enter OTP sent on mobile")
    .required("Mobile is required")
    .test("len", "Must be exactly 6 characters", (val) => val?.length === 6),
});

const emailValidation = yup.object().shape({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const emailOtpValidation = yup.object().shape({
  emailOtp: yup
    .string("Enter OTP sent on mobile")
    .required("Mobile is required")
    .test("len", "Must be exactly 6 characters", (val) => val?.length === 6),
});

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

const passwordValidation = yup.object({
  password: yup
    .string("Enter your password")
    .matches(
      passwordRegex,
      "Minimum 6 characters, at least 1 uppercase letter, at least 1 lowercase letter & at least 1 number required"
    )
    .required("Required"),

  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const hospitalValidation = yup.object().shape({
  firstName: yup.string("Enter FirstName Name").required("Required"),
  lastName: yup.string("Enter LastName Name").required("Required"),
  hospitalName: yup.string("Enter Hospital Name").required("Required"),
  country: yup
    .object()
    .shape({
      label: yup.string("Enter Country Name").required("Required"),
    })
    .typeError("Choose a country"),

  state: yup
    .object()
    .shape({
      label: yup.string("Enter state").required("Required"),
    })
    .typeError("Choose a state"),
  city: yup
    .object()
    .shape({
      label: yup.string("Enter City").required("Required"),
    })
    .typeError("Choose a city"),
});

const departmentValidation = yup.object().shape({
  departments: yup
    .array()
    .of(yup.object({ name: yup.string().required("Required") }))
    .min(1, "Choose at least one department")
    .compact(),
});

const wardValidation = yup.object().shape({
  wards: yup
    .array()
    .of(yup.object({ name: yup.string().required("Required") }))
    .min(1, "Add at least one ward from your hospital.")
    .compact(),
});

const bedValidation = yup.object().shape({
  beds: yup
    .array()
    .of(yup.object({ bedName: yup.string().required("Required") }))
    .min(1, "Add at least one bed from your hospital.")
    .compact(),
});

export const validationSchema = [
  mobileValidation,
  mobileOtpValidation,
  emailValidation,
  emailOtpValidation,
  passwordValidation,
  hospitalValidation,
  departmentValidation,
  wardValidation,
  bedValidation,
];
