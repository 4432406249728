import { useState } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/system/Box";
import CardItem from "./CardItem";
import MyDialog from "../dialog/MyDialog";

export default function SubscriptionCard({ plan, activePlan, features }) {
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const handleYes = () => {
    setOpen(false);
  };

  return (
    <Card
      sx={{
        minWidth: 160,
        textAlign: "left",
        padding: 2,
      }}
    >
      <CardActions sx={{ my: 2 }}>
        {plan?.title === activePlan ? (
          <Button variant="contained" sx={{ width: "100%" }}>
            Currently Active
          </Button>
        ) : (
          <Button
            onClick={() => openDialog()}
            variant="outlined"
            sx={{ width: "100%" }}
          >
            Start Plan
          </Button>
        )}
      </CardActions>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="subtitle1">
              {plan?.price === 0 ? null : "Rs:"}
            </Typography>
            <Typography
              sx={{ ...(plan?.price !== 0 && { marginLeft: 1 }) }}
              variant="subtitle1"
            >
              {plan?.price === 0 ? "Free" : `${plan?.price}/user/month`}
            </Typography>
          </Box>
          <Typography mb={1} variant="h3">
            {plan?.title}
          </Typography>

          <Typography variant="body2">{plan?.subtitle} </Typography>
        </Box>
        {features?.map((feature) => (
          <CardItem
            key={feature.feature}
            feature={feature}
            planTitle={plan?.title}
          />
        ))}
      </CardContent>
      <MyDialog
        handleYes={handleYes}
        open={open}
        closeDialog={closeDialog}
        text="Contact Daily Doc sales at email: dailydocllc@gmail.com or phone: +91 9699569785  or WhatsApp +91 9699569785."
      />
    </Card>
  );
}
