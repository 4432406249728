import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { logoutAction } from "../../redux/auth/authAction";

import { drawerWidth, drawerBackground } from "./utilAminNavbar";

const AdminAppBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        // boxShadow: 'none',
      }}
    >
      <Container maxWidth="false">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "flex" } }}></Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {/** <Typography sx={{ minWidth: 100 }}>Dark Mode</Typography> */}

            <Tooltip title="Account settings">
              <IconButton
                size="small"
                sx={{ ml: 2 }}
                onClick={handleClick}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                    border: "1px solid",
                    backgroundColor: "background.default",
                    "&:hover": {
                      backgroundColor: (theme) => theme.palette.neutral[200],
                    },
                    color: (theme) => theme.palette.neutral[500],
                  }}
                >
                  <Typography>
                    {user.firstName.charAt(0).toUpperCase()}
                  </Typography>
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                width: 240,
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              // button
              onClick={() => {
                navigate("/dashboard/profile");
              }}
            >
              <Avatar fontSize="small" /> Profile
            </MenuItem>
            <Divider />

            <MenuItem
              // button
              onClick={() => {
                navigate("/dashboard/settings");
              }}
            >
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem
              // button
              onClick={() => {
                dispatch(logoutAction());
                navigate("/");
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AdminAppBar;
