import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: false,
  plugins: {
    legend: {
      display: false,
      //   position: "top",
    },
    title: {
      display: true,
      text: "Number of users added to Daily Doc",
    },
  },
};
const labels = ["Access granted", "Registered", "Suspended", "Deleted"];

export default function UserStatusBarChart({ userCounts }) {
  const data = {
    labels: userCounts?.labels,
    datasets: [
      {
        label: "Daily Doc users",
        data: userCounts?.data,
        backgroundColor: ["#10B981", "#FFB020", "#5048E5", "#D14343"],
      },
    ],
  };

  return (
    <Card
      sx={{
        height: "100%",
        boxShadow: 8,
      }}
    >
      <CardContent>
        <Bar options={options} data={data} height={350} />
      </CardContent>
    </Card>
  );
}
