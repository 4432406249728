import {
  ACCESSCONTROL_DATA_LOADED,
  ACCESSCONTROL_ERROR,
  ACCESSCONTROL_MOBILE_ADDED,
} from './AccessControlTypes';

export const loadAccessControlDataAction = (data) => (dispatch) => {
  dispatch({
    type: ACCESSCONTROL_DATA_LOADED,
    payload: data,
  });
};

export const accessControlErrorAction = () => (dispatch) => {
  dispatch({
    type: ACCESSCONTROL_ERROR,
  });
};

export const accessControlMobileAddedAction = () => (dispatch) => {
  dispatch({
    tyep: ACCESSCONTROL_MOBILE_ADDED,
  });
};
