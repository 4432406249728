import { forwardRef } from 'react';
import TextField from '@mui/material/TextField';

const MyPhoneInput = (props, ref) => {
  //   console.log('from phone input:', props);
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      // size='small'
      label='Mobile Number'
      variant='outlined'
      name='phone'
      error={props.error}
      helperText={props.helperText}
    />
  );
};
export default forwardRef(MyPhoneInput);
