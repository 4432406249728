import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutAction } from "../../redux/auth/authAction";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import Button from "@mui/material/Button";
import api from "../../utilities/api";
import setAuthToken from "../../utilities/setAuthToken";
import {
  loginFailureAction,
  loginSuccessAction,
} from "../../redux/auth/authAction";

function SelectHospital() {
  const auth = useSelector((state) => state.auth);

  const { hospitalProfiles, user } = auth;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "grey.300",
      }}
    >
      <Card sx={{ maxWidth: 500, minWidth: 350 }}>
        <Box
          sx={{
            backgroundColor: "primary.main",
            height: 70,
            display: "flex",
            alignItems: "center",
            padding: 2,
            justifyContent: "center",
          }}
        >
          <Typography color="#fff" variant="h6">
            Select Hospital
          </Typography>
        </Box>
        <CardContent>
          <List>
            {hospitalProfiles.map((item, indx) => (
              <ListItem
                key={indx}
                button
                onClick={async () => {
                  const data = {
                    userDetails: {
                      user: user?._id,
                      hospitalId: item.hospital._id,
                      type: "web",
                    },
                  };
                  if (item.hospital.profile.webOnboarded) {
                    try {
                      const response = await api.post("/auth/user", data);
                      if (response) {
                        const { data } = response.data;
                        setAuthToken(data.token);
                        dispatch(loginSuccessAction(data));
                        navigate("/dashboard");
                      }
                    } catch (err) {
                      if (err) {
                        dispatch(loginFailureAction());
                      }
                    }
                  }

                  if (!item.hospital.profile.webOnboarded) {
                    // ge token for onboarding
                    // may have to make API call again to do that.
                    // then redirect to edit onboarding
                    // dispatch(loadObUserAction(response.data.data.user));
                    // for now we will just direct to onboarding
                    navigate("/edit-onboarding");
                  }
                }}
              >
                <ListItemAvatar>
                  <Avatar>
                    <AccountBalanceOutlinedIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>{item.hospital.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              dispatch(logoutAction());
              navigate("/", {});
            }}
            startIcon={<KeyboardBackspaceOutlinedIcon />}
          >
            Cancel
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default SelectHospital;
