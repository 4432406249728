import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const StyledPaper1 = styled(Paper, {
  // name and slot are for debuggin. It will apply these names in dom
  name: 'StyledPaper',
  slot: 'Wrapper',
})({
  width: 300,
  height: 300,
  borderRadius: 2,
  color: '#6bb068',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'silver',
  // it can be used to target nested CSS selectors ex Mui Button root
  //   these CSS classes can be picked from the Devtools looking at the dom
  '.MuiButton-root': { color: '#ff0000' },

  // to setup up background image use following syntax
  // backgroundImage: `url('https://picsum.photos/300/300') `
});

const StyledPaper2 = styled(Paper, {})`
  width: 300px;
  height: 300px;
  background-color: #6bb0f8;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: silver;
  /* background-image: url('https://picsum.phots/300/300'); */
`;

function DemoStylePaper() {
  // sx prop accepts mui sytem values as well. so very poweful

  const styles = {
    mt: 2,
    // tts easy to make things responsive with sx props ex
    width: { sm: 200, md: 300, lg: 400 },
    // setting up background color in a responsive way
    backgroundColor: { xs: 'secondary.light', sm: '#0000ff' },
    // there are multiple box shadows in mui. there are around 25 of them.
    //its a zero based assay
    boxShadow: 6,
  };
  return (
    <Container>
      <StyledPaper1>
        <Button variant='outlined'>Im a button</Button>
      </StyledPaper1>
      <StyledPaper2>
        <Button variant='outlined'>Im a button</Button>
      </StyledPaper2>

      <Box marginTop={0}>
        {/** this is common styling using sx syntax. Declare styles above. Its styles const right above. 
        then apply the styles as below on sx props sx={{...styles}} */}
        <TextField
          // sx prop accepts short forms names from css values as well.
          //  its theem aware
          //   se accepts css values too
          sx={{ ...styles, mb: 2, border: 'solid red 2px' }}
          id='email '
          l
          abel='Email'
        />
        <TextField sx={{ ...styles }} id='password ' label='password' />
      </Box>
    </Container>
  );
}

export default DemoStylePaper;
