export const regex10DigitNumber = /^[1-9][0-9]{9}$/;
export const regex1To999 = /^([1-9]|[1-9][0-9]{1,2})$/;
// regex for number from 1000 to 999999999999999;

const regex1000To15x9 =
  /^(100\d|10[1-9]\d|1[1-9]\d{2}|[2-9]\d{3}|[1-9]\d{4,14})$/;

export const validateCSV = (data) => {
  let result = {
    isErr: false,
    data: [],
  };
  let errString = "";
  const validatedData = data.map((item, indx) => {
    let isCountryCodeValid = regex1To999.test(item.countryCode);
    let isMobileValid = regex10DigitNumber.test(item.mobile);
    if (isCountryCodeValid && isMobileValid) {
      return {
        ...item,
        id: indx + 1,
        message: "",
      };
    } else {
      result.isErr = true;
      if (!isMobileValid && isCountryCodeValid) {
        errString = "Mobile number not valid";
      } else if (!isMobileValid && !isCountryCodeValid) {
        errString = "Country code & mobile number not valid";
      } else {
        errString = "Country code not valid";
      }
      return {
        ...item,
        id: indx + 1,
        message: errString,
      };
    }
  });
  result.data = validatedData;
  return result;
};
