import { WARDS_LOADED } from './wardsTypes';

const initialState = {
  isLoading: true,
  isError: false,
  wards: [],
};

export default function wardsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case WARDS_LOADED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        wards: payload,
      };

    default:
      return {
        ...state,
      };
  }
}
