import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { clearSnackbarAction } from "../../redux/alert/alertAction";

export const SnackbarComp = () => {
  const { snackbarType, snackbarMessage, snackbarOpen } = useSelector(
    (state) => state.snackbar
  );
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(clearSnackbarAction());
  };

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={handleClose} severity={snackbarType} sx={{ width: 600 }}>
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};
