import { useCSVDownloader } from "react-papaparse";

export default function CSVDownloader() {
  const { CSVDownloader, Type } = useCSVDownloader();

  return (
    <CSVDownloader
      // type={Type.Button}
      filename={"dailydoc"}
      bom={true}
      config={{
        delimiter: ";",
      }}
      data={() => {
        return [
          {
            countryCode: "91",
            mobile: "9699569785",
          },
        ];
      }}
    >
      Download Sample CSV
    </CSVDownloader>
  );
}
