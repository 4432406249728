import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import MyPhoneInput from './MyPhoneInput';
import { parsePhoneNumber } from 'react-phone-number-input';

const initialValues = {
  phone: '',
};

const validationSchema = yup.object({
  phone: yup.string().required('Required'),
});

const onSubmit = (values, actions) => {
  console.log('from form submit values: =>', values);
  const phoneNumber = parsePhoneNumber(values.phone);
  console.log('phoneNumber Object =>', phoneNumber);
  console.log(
    'country code from phoneNumber Object=>',
    phoneNumber.countryCallingCode
  );
};

function MobileCode() {
  const [defaultCountry, setdefaultCountry] = useState('IN');

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  //   function handleChange(e) {
  //     setValue(e.target.value);
  //   }

  // function handleSubmit() {
  //   alert('A phone No was submitted: ' + value);
  // }

  async function getGeoInfo() {
    try {
      const response = await axios.get('https://ipapi.co/json/');
      if (response) {
        setdefaultCountry(response.data.country_code);
      }
    } catch (err) {
      if (err) setdefaultCountry('IN');
    }
  }

  useEffect(() => {
    getGeoInfo();
  }, []);

  return (
    <div style={{ margin: 50 }}>
      <form onSubmit={formik.handleSubmit}>
        <PhoneInput
          type='text'
          // defaultCountry='IN'
          defaultCountry={defaultCountry}
          international
          value={formik.values['phone']}
          onChange={(e) => formik.setFieldValue('phone', e)}
          inputComponent={MyPhoneInput}
          rules={{ required: true }}
          error={Boolean(formik.errors.phone)}
          helperText={formik.errors.phone}
        />

        <input type='submit' value='Submit' />
      </form>
    </div>
  );
}

export default MobileCode;
