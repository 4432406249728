import { useState, useCallback, useMemo } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import TextError from "../formik/TextError";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import Box from "@mui/system/Box";

import ObWard from "./ObWard";
import EditObWard from "./EditObWard";
import DialogAlert from "../alerts/DialogAlert";

export default function ObWardsPage({ formik }) {
  const [page, setPage] = useState(0);
  const [isWardsDialogOpen, setIsWardsDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState([]);
  const [rowToDelete, setRowToDelete] = useState([]);
  const [deleteErr, setdeleteErr] = useState("");
  const [wardErr, setWardErr] = useState("");

  // edit row
  const editRow = useCallback(
    (id, row) => () => {
      setRowToEdit(row);
      formik.setFieldValue("ward", row?.name);
      setIsEditDialogOpen(true);
    },
    []
  );

  // open delete dialogalert
  const openDeleteDialog = useCallback(
    (id, row) => () => {
      setRowToDelete(row);
      setIsDeleteDialogOpen(true);
    },
    []
  );

  //   delet ward
  const deleteWard = () => {
    formik.setFieldValue(
      "wards",
      formik.values.wards.filter((ward) => ward.id !== rowToDelete?.id)
    );
    if (formik.values.beds?.length > 0) {
      formik.setFieldValue(
        "beds",
        formik.values.beds.filter((item) => item.wardId !== rowToDelete?._id)
      );
    }
    setIsDeleteDialogOpen(false);
  };

  // close dialoge
  const closeEditDialog = () => {
    setIsEditDialogOpen(false);
    setRowToEdit([]);
    setWardErr("");
    formik.setFieldValue("ward", "");
  };
  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setdeleteErr("");
    // setRowToDelete([]);
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Wards",
        description: "Wards",
        type: "string",
        minWidth: 150,
        flex: 1,
      },

      {
        field: "actions",
        headerName: "Actions",
        description: "Modify table",
        type: "actions",
        minWidth: 120,
        flex: 1,
        getActions: (params) => {
          // console.log(params.row);
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={openDeleteDialog(params.id, params.row)}
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={editRow(params.id, params.row)}
              // showInMenu
            />,
          ];
        },
      },
    ],
    [openDeleteDialog]
  );

  return (
    <Box>
      <Typography mb={2}>
        Add names of wards in your hospital by clicking on +Add wards button.
        Add one ward at a time.
      </Typography>
      <Button
        sx={{ marginBottom: 3 }}
        color="primary"
        variant="contained"
        type="button"
        startIcon={<AddIcon />}
        onClick={() => setIsWardsDialogOpen(true)}
      >
        {formik.values.wards.length > 0 ? "Add more wards" : "Add ward"}
      </Button>
      {formik.touched.wards && Boolean(formik.errors.wards) && (
        <Box sx={{ color: "error.main" }}>{formik.errors.wards}</Box>
      )}
      {formik.values.wards?.length > 0 && (
        <>
          <Typography mb={1}>Wards table</Typography>
          <Box
            sx={{
              // height: 400,
              width: "100%",
            }}
          >
            <DataGrid
              sx={{
                boxShadow: 2,
                padding: 1,
                border: 1,
                borderColor: "divider",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              autoHeight
              columns={columns}
              rows={formik.values.wards}
              //   pagination
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              pageSize={5}
              rowsPerPageOptions={[5]}
              pagination
            />
          </Box>
        </>
      )}
      <ObWard
        open={isWardsDialogOpen}
        formik={formik}
        wardErr={wardErr}
        setWardErr={setWardErr}
        onClose={() => {
          setIsWardsDialogOpen(false);
          setWardErr("");
          formik.setFieldValue("ward", "");
        }}
      />
      <EditObWard
        open={isEditDialogOpen}
        formik={formik}
        onClose={closeEditDialog}
        rowToEdit={rowToEdit}
        setRowToEdit={setRowToEdit}
        wardErr={wardErr}
        setWardErr={setWardErr}
      />
      <DialogAlert
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        handleYes={deleteWard}
        title={`Delete: ${rowToDelete?.name}`}
        msg={
          deleteErr ||
          `Are you sure you want to delete ward- ${rowToDelete?.name}? Any associated beds will be deleted as well.`
        }
      />
    </Box>
  );
}
