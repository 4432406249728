import { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/system";
import Autocomplete from "@mui/material/Autocomplete";

import api from "../../utilities/api";
import { getBedsData } from "./utilBeds";
import { getWardsData } from "../wards/utilWards";

const StyledForm = styled("form")({
  width: "100%",
  ".MuiTextField-root": { marginTop: "0" },
});

const validationSchema = yup.object({
  ward: yup
    .object({ name: yup.string().required("Required") })
    .typeError("Required"),
  //   ward: yup.string().required('Required').nullable(),
  bed: yup.string("Provide bed number").required("Required"),
});

export default function AddBedForm({ wards }) {
  const hospital = useSelector((state) => state.auth.hospital);

  useEffect(() => {
    getWardsData(hospital._id);
    return () => {};
  }, [hospital]);

  const initialValues = {
    ward: wards[0] || null,
    bed: "",
  };

  const onSubmit = async (values, actions) => {
    const { bed, ward } = values;
    const nextValues = {
      ward,
      bed: "",
    };

    const formData = {
      hospital: hospital._id,
      beds: [
        {
          name: bed,
        },
      ],
    };

    const url = `/hospitals/${hospital._id}/wards/${ward?._id}/beds`;
    try {
      const response = await api.post(url, formData);
      if (response) {
        getBedsData(hospital._id);
        actions.resetForm({
          values: nextValues /* errors, touched, etc ... */,
        });
        actions.setStatus({ success: true });
      }
    } catch (err) {
      if (err) {
        actions.setStatus({ success: false });
        actions.setFieldError("bed", err.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <StyledForm
      className="employee-form"
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <Grid container spacing={{ xs: 1 }}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            value={formik.values.ward}
            name="ward"
            options={wards}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            onChange={(event, newValue) => {
              formik.setFieldValue("ward", newValue);
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                onChange={formik.handleChange}
                margin="normal"
                label="Wards*"
                fullWidth
                // variant='standard'
                size="small"
                // required
                value={formik.values?.ward}
                error={formik.touched.ward && Boolean(formik.errors.ward)}
                helperText={formik.touched.ward && formik.errors.ward}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            sx={{ marginRight: 2 }}
            size="small"
            fullWidth
            id="bed"
            name="bed"
            label="Bed*"
            value={formik.values.bed}
            onChange={formik.handleChange}
            error={formik.touched.bed && Boolean(formik.errors.bed)}
            helperText={formik.touched.bed && formik.errors.bed}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Box>
            <Button
              sx={{ float: "right" }}
              type="submit"
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              disabled={formik.isSubmitting}
            >
              Add
            </Button>
          </Box>
        </Grid>
      </Grid>
    </StyledForm>
  );
}
