import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import SettingsIcon from "@mui/icons-material/Settings";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import HelpIcon from "@mui/icons-material/Help";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";

// set side drawer width
export const drawerWidth = 250;
// set drawer background color
export const drawerBackground = "neutral.900";
export const drawerTextColor = "neutral.300";
export const drawerActiveListBackgroundColor = "rgba(255,255,255, 0.08)";

export function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

// set drawer menu options
// set drawer submenu options and their paths

export const drawerMenuOptions = [
  { text: "Dasbboard", icon: <DashboardIcon />, path: "/dashboard", items: [] },
  {
    text: "Add Users to Daily Doc App",
    icon: <AdminPanelSettingsIcon />,
    path: "/dashboard/access-control",
    items: [],
  },
  {
    text: "All Users",
    icon: <AssignmentIndIcon />,
    path: "/dashboard/employees",
  },

  {
    text: "Hospital",
    icon: <CorporateFareIcon />,
    items: [
      {
        text: "Departments",
        path: "/dashboard/departments",
      },
      {
        text: "Wards",
        path: "/dashboard/wards",
      },
      {
        text: "Beds",
        path: "/dashboard/beds",
      },
    ],
  },

  {
    text: "Account",
    icon: <SettingsIcon />,
    items: [
      {
        text: "Profile",
        path: "/dashboard/profile",
      },
      {
        text: "Manage Admins",
        path: "/dashboard/admins",
      },
      {
        text: "Manage Hospitals",
        path: "/dashboard/hospitals",
      },
      // {
      //   text: "Reset Password",
      //   path: "/dashboard/change-password",
      // },
      {
        text: "Settings",
        path: "/dashboard/settings",
      },
    ],
  },
  {
    text: "Subscription",
    icon: <SubscriptionsIcon />,
    path: "/dashboard/subscription",
  },
  // { text: "Reports", icon: <ReportIcon />, path: "/dashboard/reports" },
  // { text: "Invoices", icon: <ReceiptIcon />, path: "/dashboard/invoices" },
  {
    text: "Instructions",
    icon: <HelpIcon />,
    path: "/dashboard/instructions",
  },
  { text: "Feedback", icon: <ThumbUpAltIcon />, path: "/dashboard/feedback" },
];
