import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countryList } from "../../utilities/countryList";

export default function CountryAutocomplete({ formik }) {
  return (
    <>
      <Autocomplete
        options={countryList}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        id="country"
        value={formik.values.country}
        onChange={(event, newValue) => {
          if (formik.values.country?.countryId !== newValue?.countryId) {
            formik.setFieldValue("state", null);
            formik.setFieldValue("city", null);
          }
          formik.setFieldValue("country", newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoComplete="off"
            label="Country"
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          />
        )}
      />
    </>
  );
}
