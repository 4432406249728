import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MovingIcon from "@mui/icons-material/Moving";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NumberCard({ header, num, icon, path }) {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate(`${path}`)}
      sx={{
        height: "100%",
        boxShadow: 8,
        ":hover": { cursor: "pointer", backgroundColor: "action.hover" },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Typography mb={1} sx={{ fontWeight: 900, letterSpacing: 1 }}>
          {header}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <MovingIcon fontSize="medium" />
          {icon}
        </Box>
        <Box sx={{ height: 60 }}>
          <Typography variant="h2">{num ?? num}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
