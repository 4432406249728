export const countryList = [
  {
    _id: "63df3eef33c398319d2ec275",
    label: "Anguilla",
    value: "Anguilla",
    countryId: 8,
  },
  {
    _id: "63df3eef33c398319d2ec271",
    label: "Andorra",
    value: "Andorra",
    countryId: 6,
  },
  {
    _id: "63df3eef33c398319d2ec273",
    label: "Angola",
    value: "Angola",
    countryId: 7,
  },
  {
    _id: "63df3eef33c398319d2ec267",
    label: "Afghanistan",
    value: "Afghanistan",
    countryId: 1,
  },
  {
    _id: "63df3eef33c398319d2ec26d",
    label: "Algeria",
    value: "Algeria",
    countryId: 4,
  },
  {
    _id: "63df3eef33c398319d2ec26f",
    label: "American Samoa",
    value: "American Samoa",
    countryId: 5,
  },
  {
    _id: "63df3eef33c398319d2ec26b",
    label: "Albania",
    value: "Albania",
    countryId: 3,
  },
  {
    _id: "63df3eef33c398319d2ec282",
    label: "Antigua And Barbuda",
    value: "Antigua And Barbuda",
    countryId: 10,
  },
  {
    _id: "63df3eef33c398319d2ec269",
    label: "Aland Islands",
    value: "Aland Islands",
    countryId: 2,
  },
  {
    _id: "63df3eef33c398319d2ec277",
    label: "Antarctica",
    value: "Antarctica",
    countryId: 9,
  },
  {
    _id: "63df3eef33c398319d2ec293",
    label: "Aruba",
    value: "Aruba",
    countryId: 13,
  },
  {
    _id: "63df3eef33c398319d2ec295",
    label: "Australia",
    value: "Australia",
    countryId: 14,
  },
  {
    _id: "63df3eef33c398319d2ec2a5",
    label: "Azerbaijan",
    value: "Azerbaijan",
    countryId: 16,
  },
  {
    _id: "63df3eef33c398319d2ec2a3",
    label: "Austria",
    value: "Austria",
    countryId: 15,
  },
  {
    _id: "63df3eef33c398319d2ec2ab",
    label: "Barbados",
    value: "Barbados",
    countryId: 20,
  },
  {
    _id: "63df3eef33c398319d2ec2a7",
    label: "Bahrain",
    value: "Bahrain",
    countryId: 18,
  },
  {
    _id: "63df3eef33c398319d2ec2a9",
    label: "Bangladesh",
    value: "Bangladesh",
    countryId: 19,
  },
  {
    _id: "63df3eef33c398319d2ec2b1",
    label: "Belize",
    value: "Belize",
    countryId: 23,
  },
  {
    _id: "63df3eef33c398319d2ec2ad",
    label: "Belarus",
    value: "Belarus",
    countryId: 21,
  },
  {
    _id: "63df3eef33c398319d2ec2af",
    label: "Belgium",
    value: "Belgium",
    countryId: 22,
  },
  {
    _id: "63df3eef33c398319d2ec2b3",
    label: "Benin",
    value: "Benin",
    countryId: 24,
  },
  {
    _id: "63df3eef33c398319d2ec2b5",
    label: "Bermuda",
    value: "Bermuda",
    countryId: 25,
  },
  {
    _id: "63df3eef33c398319d2ec2b9",
    label: "Bolivia",
    value: "Bolivia",
    countryId: 27,
  },
  {
    _id: "63df3eef33c398319d2ec2b7",
    label: "Bhutan",
    value: "Bhutan",
    countryId: 26,
  },
  {
    _id: "63df3eef33c398319d2ec2bb",
    label: "Bonaire, Sint Eustatius and Saba",
    value: "Bonaire, Sint Eustatius and Saba",
    countryId: 155,
  },
  {
    _id: "63df3eef33c398319d2ec2bd",
    label: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
    countryId: 28,
  },
  {
    _id: "63df3eef33c398319d2ec2bf",
    label: "Botswana",
    value: "Botswana",
    countryId: 29,
  },
  {
    _id: "63df3eef33c398319d2ec2c1",
    label: "Bouvet Island",
    value: "Bouvet Island",
    countryId: 30,
  },
  {
    _id: "63df3eef33c398319d2ec2c3",
    label: "Brazil",
    value: "Brazil",
    countryId: 31,
  },
  {
    _id: "63df3eef33c398319d2ec2d4",
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
    countryId: 32,
  },
  {
    _id: "63df3eef33c398319d2ec2d8",
    label: "Bulgaria",
    value: "Bulgaria",
    countryId: 34,
  },
  {
    _id: "63df3eef33c398319d2ec2d6",
    label: "Brunei",
    value: "Brunei",
    countryId: 33,
  },
  {
    _id: "63df3eef33c398319d2ec2da",
    label: "Burkina Faso",
    value: "Burkina Faso",
    countryId: 35,
  },
  {
    _id: "63df3eef33c398319d2ec2e0",
    label: "Cameroon",
    value: "Cameroon",
    countryId: 38,
  },
  {
    _id: "63df3eef33c398319d2ec2dc",
    label: "Burundi",
    value: "Burundi",
    countryId: 36,
  },
  {
    _id: "63df3eef33c398319d2ec2e2",
    label: "Canada",
    value: "Canada",
    countryId: 39,
  },
  {
    _id: "63df3eef33c398319d2ec2de",
    label: "Cambodia",
    value: "Cambodia",
    countryId: 37,
  },
  {
    _id: "63df3eef33c398319d2ec303",
    label: "Central African Republic",
    value: "Central African Republic",
    countryId: 42,
  },
  {
    _id: "63df3eef33c398319d2ec2ff",
    label: "Cape Verde",
    value: "Cape Verde",
    countryId: 40,
  },
  {
    _id: "63df3eef33c398319d2ec301",
    label: "Cayman Islands",
    value: "Cayman Islands",
    countryId: 41,
  },
  {
    _id: "63df3eef33c398319d2ec305",
    label: "Chad",
    value: "Chad",
    countryId: 43,
  },
  {
    _id: "63df3eef33c398319d2ec307",
    label: "Chile",
    value: "Chile",
    countryId: 44,
  },
  {
    _id: "63df3eef33c398319d2ec30b",
    label: "China",
    value: "China",
    countryId: 45,
  },
  {
    _id: "63df3eef33c398319d2ec30e",
    label: "Christmas Island",
    value: "Christmas Island",
    countryId: 46,
  },
  {
    _id: "63df3eef33c398319d2ec310",
    label: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands",
    countryId: 47,
  },
  {
    _id: "63df3eef33c398319d2ec312",
    label: "Colombia",
    value: "Colombia",
    countryId: 48,
  },
  {
    _id: "63df3eef33c398319d2ec316",
    label: "Congo",
    value: "Congo",
    countryId: 50,
  },
  {
    _id: "63df3eef33c398319d2ec314",
    label: "Comoros",
    value: "Comoros",
    countryId: 49,
  },
  {
    _id: "63df3ef033c398319d2ec31c",
    label: "Cote D'Ivoire (Ivory Coast)",
    value: "Cote D'Ivoire (Ivory Coast)",
    countryId: 54,
  },
  {
    _id: "63df3eef33c398319d2ec318",
    label: "Cook Islands",
    value: "Cook Islands",
    countryId: 52,
  },
  {
    _id: "63df3eef33c398319d2ec31a",
    label: "Costa Rica",
    value: "Costa Rica",
    countryId: 53,
  },
  {
    _id: "63df3ef033c398319d2ec31e",
    label: "Croatia",
    value: "Croatia",
    countryId: 55,
  },
  {
    _id: "63df3ef033c398319d2ec320",
    label: "Cuba",
    value: "Cuba",
    countryId: 56,
  },
  {
    _id: "63df3ef033c398319d2ec322",
    label: "Curaçao",
    value: "Curaçao",
    countryId: 249,
  },
  {
    _id: "63df3ef033c398319d2ec324",
    label: "Cyprus",
    value: "Cyprus",
    countryId: 57,
  },
  {
    _id: "63df3ef033c398319d2ec327",
    label: "Czech Republic",
    value: "Czech Republic",
    countryId: 58,
  },
  {
    _id: "63df3ef033c398319d2ec329",
    label: "Democratic Republic of the Congo",
    value: "Democratic Republic of the Congo",
    countryId: 51,
  },
  {
    _id: "63df3ef033c398319d2ec330",
    label: "Dominica",
    value: "Dominica",
    countryId: 61,
  },
  {
    _id: "63df3ef033c398319d2ec32e",
    label: "Djibouti",
    value: "Djibouti",
    countryId: 60,
  },
  {
    _id: "63df3ef033c398319d2ec32c",
    label: "Denmark",
    value: "Denmark",
    countryId: 59,
  },
  {
    _id: "63df3ef033c398319d2ec332",
    label: "Dominican Republic",
    value: "Dominican Republic",
    countryId: 62,
  },
  {
    _id: "63df3ef033c398319d2ec339",
    label: "Egypt",
    value: "Egypt",
    countryId: 65,
  },
  {
    _id: "63df3ef033c398319d2ec334",
    label: "East Timor",
    value: "East Timor",
    countryId: 63,
  },
  {
    _id: "63df3ef033c398319d2ec336",
    label: "Ecuador",
    value: "Ecuador",
    countryId: 64,
  },
  {
    _id: "63df3ef033c398319d2ec33b",
    label: "El Salvador",
    value: "El Salvador",
    countryId: 66,
  },
  {
    _id: "63df3ef033c398319d2ec33d",
    label: "Equatorial Guinea",
    value: "Equatorial Guinea",
    countryId: 67,
  },
  {
    _id: "63df3ef033c398319d2ec33f",
    label: "Eritrea",
    value: "Eritrea",
    countryId: 68,
  },
  {
    _id: "63df3ef033c398319d2ec341",
    label: "Estonia",
    value: "Estonia",
    countryId: 69,
  },
  {
    _id: "63df3ef033c398319d2ec347",
    label: "Faroe Islands",
    value: "Faroe Islands",
    countryId: 72,
  },
  {
    _id: "63df3ef033c398319d2ec345",
    label: "Falkland Islands",
    value: "Falkland Islands",
    countryId: 71,
  },
  {
    _id: "63df3ef033c398319d2ec343",
    label: "Ethiopia",
    value: "Ethiopia",
    countryId: 70,
  },
  {
    _id: "63df3ef033c398319d2ec34b",
    label: "Finland",
    value: "Finland",
    countryId: 74,
  },
  {
    _id: "63df3ef033c398319d2ec349",
    label: "Fiji Islands",
    value: "Fiji Islands",
    countryId: 73,
  },
  {
    _id: "63df3ef033c398319d2ec34d",
    label: "France",
    value: "France",
    countryId: 75,
  },
  {
    _id: "63df3ef033c398319d2ec34f",
    label: "French Guiana",
    value: "French Guiana",
    countryId: 76,
  },
  {
    _id: "63df3ef033c398319d2ec351",
    label: "French Polynesia",
    value: "French Polynesia",
    countryId: 77,
  },
  {
    _id: "63df3ef033c398319d2ec355",
    label: "French Southern Territories",
    value: "French Southern Territories",
    countryId: 78,
  },
  {
    _id: "63df3ef033c398319d2ec359",
    label: "Gambia The",
    value: "Gambia The",
    countryId: 80,
  },
  {
    _id: "63df3ef033c398319d2ec357",
    label: "Gabon",
    value: "Gabon",
    countryId: 79,
  },
  {
    _id: "63df3ef033c398319d2ec360",
    label: "Ghana",
    value: "Ghana",
    countryId: 83,
  },
  {
    _id: "63df3ef033c398319d2ec35d",
    label: "Germany",
    value: "Germany",
    countryId: 82,
  },
  {
    _id: "63df3ef033c398319d2ec35b",
    label: "Georgia",
    value: "Georgia",
    countryId: 81,
  },
  {
    _id: "63df3ef033c398319d2ec364",
    label: "Greece",
    value: "Greece",
    countryId: 85,
  },
  {
    _id: "63df3ef033c398319d2ec362",
    label: "Gibraltar",
    value: "Gibraltar",
    countryId: 84,
  },
  {
    _id: "63df3ef033c398319d2ec366",
    label: "Greenland",
    value: "Greenland",
    countryId: 86,
  },
  {
    _id: "63df3ef033c398319d2ec36d",
    label: "Guadeloupe",
    value: "Guadeloupe",
    countryId: 88,
  },
  {
    _id: "63df3ef033c398319d2ec36b",
    label: "Grenada",
    value: "Grenada",
    countryId: 87,
  },
  {
    _id: "63df3ef033c398319d2ec36f",
    label: "Guam",
    value: "Guam",
    countryId: 89,
  },
  {
    _id: "63df3eef33c398319d2ec291",
    label: "Armenia",
    value: "Armenia",
    countryId: 12,
  },
  {
    _id: "63df3ef033c398319d2ec375",
    label: "Guinea",
    value: "Guinea",
    countryId: 92,
  },
  {
    _id: "63df3ef033c398319d2ec373",
    label: "Guernsey and Alderney",
    value: "Guernsey and Alderney",
    countryId: 91,
  },
  {
    _id: "63df3eef33c398319d2ec284",
    label: "Argentina",
    value: "Argentina",
    countryId: 11,
  },
  {
    _id: "63df3ef033c398319d2ec379",
    label: "Guyana",
    value: "Guyana",
    countryId: 94,
  },
  {
    _id: "63df3ef033c398319d2ec385",
    label: "Iceland",
    value: "Iceland",
    countryId: 100,
  },
  {
    _id: "63df3ef033c398319d2ec387",
    label: "India",
    value: "India",
    countryId: 101,
  },
  {
    _id: "63df3ef033c398319d2ec37d",
    label: "Heard Island and McDonald Islands",
    value: "Heard Island and McDonald Islands",
    countryId: 96,
  },
  {
    _id: "63df3ef033c398319d2ec394",
    label: "Israel",
    value: "Israel",
    countryId: 106,
  },
  {
    _id: "63df3ef033c398319d2ec396",
    label: "Italy",
    value: "Italy",
    countryId: 107,
  },
  {
    _id: "63df3ef033c398319d2ec38e",
    label: "Iran",
    value: "Iran",
    countryId: 103,
  },
  {
    _id: "63df3ef033c398319d2ec383",
    label: "Hungary",
    value: "Hungary",
    countryId: 99,
  },
  {
    _id: "63df3ef033c398319d2ec389",
    label: "Indonesia",
    value: "Indonesia",
    countryId: 102,
  },
  {
    _id: "63df3ef033c398319d2ec390",
    label: "Iraq",
    value: "Iraq",
    countryId: 104,
  },
  {
    _id: "63df3ef033c398319d2ec37b",
    label: "Haiti",
    value: "Haiti",
    countryId: 95,
  },
  {
    _id: "63df3ef033c398319d2ec381",
    label: "Hong Kong S.A.R.",
    value: "Hong Kong S.A.R.",
    countryId: 98,
  },
  {
    _id: "63df3ef033c398319d2ec37f",
    label: "Honduras",
    value: "Honduras",
    countryId: 97,
  },
  {
    _id: "63df3ef033c398319d2ec392",
    label: "Ireland",
    value: "Ireland",
    countryId: 105,
  },
  {
    _id: "63df3ef033c398319d2ec39a",
    label: "Japan",
    value: "Japan",
    countryId: 109,
  },
  {
    _id: "63df3ef033c398319d2ec39e",
    label: "Jordan",
    value: "Jordan",
    countryId: 111,
  },
  {
    _id: "63df3ef033c398319d2ec398",
    label: "Jamaica",
    value: "Jamaica",
    countryId: 108,
  },
  {
    _id: "63df3ef033c398319d2ec39c",
    label: "Jersey",
    value: "Jersey",
    countryId: 110,
  },
  {
    _id: "63df3ef033c398319d2ec3a0",
    label: "Kazakhstan",
    value: "Kazakhstan",
    countryId: 112,
  },
  {
    _id: "63df3ef033c398319d2ec3b6",
    label: "Latvia",
    value: "Latvia",
    countryId: 120,
  },
  {
    _id: "63df3ef033c398319d2ec3b0",
    label: "Kuwait",
    value: "Kuwait",
    countryId: 117,
  },
  {
    _id: "63df3ef033c398319d2ec3b2",
    label: "Kyrgyzstan",
    value: "Kyrgyzstan",
    countryId: 118,
  },
  {
    _id: "63df3ef033c398319d2ec3a8",
    label: "Kenya",
    value: "Kenya",
    countryId: 113,
  },
  {
    _id: "63df3ef033c398319d2ec3aa",
    label: "Kiribati",
    value: "Kiribati",
    countryId: 114,
  },
  {
    _id: "63df3ef033c398319d2ec3b4",
    label: "Laos",
    value: "Laos",
    countryId: 119,
  },
  {
    _id: "63df3ef033c398319d2ec3ae",
    label: "Kosovo",
    value: "Kosovo",
    countryId: 248,
  },
  {
    _id: "63df3ef033c398319d2ec3b8",
    label: "Lebanon",
    value: "Lebanon",
    countryId: 121,
  },
  {
    _id: "63df3ef033c398319d2ec3ba",
    label: "Lesotho",
    value: "Lesotho",
    countryId: 122,
  },
  {
    _id: "63df3ef033c398319d2ec3bc",
    label: "Liberia",
    value: "Liberia",
    countryId: 123,
  },
  {
    _id: "63df3ef033c398319d2ec3be",
    label: "Libya",
    value: "Libya",
    countryId: 124,
  },
  {
    _id: "63df3ef033c398319d2ec3c4",
    label: "Luxembourg",
    value: "Luxembourg",
    countryId: 127,
  },
  {
    _id: "63df3ef033c398319d2ec3c2",
    label: "Lithuania",
    value: "Lithuania",
    countryId: 126,
  },
  {
    _id: "63df3ef033c398319d2ec3c0",
    label: "Liechtenstein",
    value: "Liechtenstein",
    countryId: 125,
  },
  {
    _id: "63df3ef033c398319d2ec3c6",
    label: "Macau S.A.R.",
    value: "Macau S.A.R.",
    countryId: 128,
  },
  {
    _id: "63df3ef033c398319d2ec3cc",
    label: "Malawi",
    value: "Malawi",
    countryId: 131,
  },
  {
    _id: "63df3ef033c398319d2ec3c8",
    label: "Macedonia",
    value: "Macedonia",
    countryId: 129,
  },
  {
    _id: "63df3ef033c398319d2ec3ca",
    label: "Madagascar",
    value: "Madagascar",
    countryId: 130,
  },
  {
    _id: "63df3ef033c398319d2ec3d1",
    label: "Maldives",
    value: "Maldives",
    countryId: 133,
  },
  {
    _id: "63df3ef033c398319d2ec3ce",
    label: "Malaysia",
    value: "Malaysia",
    countryId: 132,
  },
  {
    _id: "63df3ef033c398319d2ec3d3",
    label: "Mali",
    value: "Mali",
    countryId: 134,
  },
  {
    _id: "63df3ef033c398319d2ec371",
    label: "Guatemala",
    value: "Guatemala",
    countryId: 90,
  },
  {
    _id: "63df3ef033c398319d2ec3d7",
    label: "Man (Isle of)",
    value: "Man (Isle of)",
    countryId: 136,
  },
  {
    _id: "63df3ef033c398319d2ec3d9",
    label: "Marshall Islands",
    value: "Marshall Islands",
    countryId: 137,
  },
  {
    _id: "63df3ef033c398319d2ec3de",
    label: "Mauritania",
    value: "Mauritania",
    countryId: 139,
  },
  {
    _id: "63df3ef033c398319d2ec3e2",
    label: "Mayotte",
    value: "Mayotte",
    countryId: 141,
  },
  {
    _id: "63df3ef033c398319d2ec3e0",
    label: "Mauritius",
    value: "Mauritius",
    countryId: 140,
  },
  {
    _id: "63df3ef033c398319d2ec3f0",
    label: "Micronesia",
    value: "Micronesia",
    countryId: 143,
  },
  {
    _id: "63df3ef033c398319d2ec3e4",
    label: "Mexico",
    value: "Mexico",
    countryId: 142,
  },
  {
    _id: "63df3ef033c398319d2ec377",
    label: "Guinea-Bissau",
    value: "Guinea-Bissau",
    countryId: 93,
  },
  {
    _id: "63df3ef033c398319d2ec3f6",
    label: "Monaco",
    value: "Monaco",
    countryId: 145,
  },
  {
    _id: "63df3ef033c398319d2ec3f8",
    label: "Mongolia",
    value: "Mongolia",
    countryId: 146,
  },
  {
    _id: "63df3ef033c398319d2ec3f4",
    label: "Moldova",
    value: "Moldova",
    countryId: 144,
  },
  {
    _id: "63df3ef033c398319d2ec3fc",
    label: "Montenegro",
    value: "Montenegro",
    countryId: 147,
  },
  {
    _id: "63df3ef033c398319d2ec3fe",
    label: "Montserrat",
    value: "Montserrat",
    countryId: 148,
  },
  {
    _id: "63df3ef033c398319d2ec400",
    label: "Morocco",
    value: "Morocco",
    countryId: 149,
  },
  {
    _id: "63df3ef033c398319d2ec402",
    label: "Mozambique",
    value: "Mozambique",
    countryId: 150,
  },
  {
    _id: "63df3ef033c398319d2ec404",
    label: "Myanmar",
    value: "Myanmar",
    countryId: 151,
  },
  {
    _id: "63df3ef033c398319d2ec406",
    label: "Namibia",
    value: "Namibia",
    countryId: 152,
  },
  {
    _id: "63df3ef033c398319d2ec408",
    label: "Nauru",
    value: "Nauru",
    countryId: 153,
  },
  {
    _id: "63df3ef033c398319d2ec40c",
    label: "Netherlands",
    value: "Netherlands",
    countryId: 156,
  },
  {
    _id: "63df3ef033c398319d2ec40a",
    label: "Nepal",
    value: "Nepal",
    countryId: 154,
  },
  {
    _id: "63df3ef033c398319d2ec40e",
    label: "New Caledonia",
    value: "New Caledonia",
    countryId: 157,
  },
  {
    _id: "63df3ef033c398319d2ec410",
    label: "New Zealand",
    value: "New Zealand",
    countryId: 158,
  },
  {
    _id: "63df3ef033c398319d2ec413",
    label: "Nicaragua",
    value: "Nicaragua",
    countryId: 159,
  },
  {
    _id: "63df3ef033c398319d2ec415",
    label: "Niger",
    value: "Niger",
    countryId: 160,
  },
  {
    _id: "63df3ef033c398319d2ec419",
    label: "Niue",
    value: "Niue",
    countryId: 162,
  },
  {
    _id: "63df3ef033c398319d2ec417",
    label: "Nigeria",
    value: "Nigeria",
    countryId: 161,
  },
  {
    _id: "63df3ef033c398319d2ec41b",
    label: "Norfolk Island",
    value: "Norfolk Island",
    countryId: 163,
  },
  {
    _id: "63df3ef033c398319d2ec41d",
    label: "North Korea",
    value: "North Korea",
    countryId: 115,
  },
  {
    _id: "63df3ef033c398319d2ec41f",
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
    countryId: 164,
  },
  {
    _id: "63df3ef033c398319d2ec421",
    label: "Norway",
    value: "Norway",
    countryId: 165,
  },
  {
    _id: "63df3ef033c398319d2ec423",
    label: "Oman",
    value: "Oman",
    countryId: 166,
  },
  {
    _id: "63df3ef033c398319d2ec425",
    label: "Pakistan",
    value: "Pakistan",
    countryId: 167,
  },
  {
    _id: "63df3ef033c398319d2ec427",
    label: "Palau",
    value: "Palau",
    countryId: 168,
  },
  {
    _id: "63df3ef033c398319d2ec433",
    label: "Peru",
    value: "Peru",
    countryId: 173,
  },
  {
    _id: "63df3ef033c398319d2ec429",
    label: "Palestinian Territory Occupied",
    value: "Palestinian Territory Occupied",
    countryId: 169,
  },
  {
    _id: "63df3ef033c398319d2ec435",
    label: "Philippines",
    value: "Philippines",
    countryId: 174,
  },
  {
    _id: "63df3ef033c398319d2ec431",
    label: "Paraguay",
    value: "Paraguay",
    countryId: 172,
  },
  {
    _id: "63df3ef033c398319d2ec42e",
    label: "Papua new Guinea",
    value: "Papua new Guinea",
    countryId: 171,
  },
  {
    _id: "63df3ef033c398319d2ec42c",
    label: "Panama",
    value: "Panama",
    countryId: 170,
  },
  {
    _id: "63df3ef033c398319d2ec437",
    label: "Pitcairn Island",
    value: "Pitcairn Island",
    countryId: 175,
  },
  {
    _id: "63df3ef033c398319d2ec43b",
    label: "Portugal",
    value: "Portugal",
    countryId: 177,
  },
  {
    _id: "63df3ef033c398319d2ec439",
    label: "Poland",
    value: "Poland",
    countryId: 176,
  },
  {
    _id: "63df3ef033c398319d2ec43f",
    label: "Puerto Rico",
    value: "Puerto Rico",
    countryId: 178,
  },
  {
    _id: "63df3ef033c398319d2ec443",
    label: "Reunion",
    value: "Reunion",
    countryId: 180,
  },
  {
    _id: "63df3ef033c398319d2ec441",
    label: "Qatar",
    value: "Qatar",
    countryId: 179,
  },
  {
    _id: "63df3ef033c398319d2ec445",
    label: "Romania",
    value: "Romania",
    countryId: 181,
  },
  {
    _id: "63df3ef033c398319d2ec462",
    label: "Rwanda",
    value: "Rwanda",
    countryId: 183,
  },
  {
    _id: "63df3ef033c398319d2ec447",
    label: "Russia",
    value: "Russia",
    countryId: 182,
  },
  {
    _id: "63df3ef033c398319d2ec464",
    label: "Saint Helena",
    value: "Saint Helena",
    countryId: 184,
  },
  {
    _id: "63df3ef033c398319d2ec466",
    label: "Saint Kitts And Nevis",
    value: "Saint Kitts And Nevis",
    countryId: 185,
  },
  {
    _id: "63df3ef033c398319d2ec46a",
    label: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
    countryId: 187,
  },
  {
    _id: "63df3ef033c398319d2ec468",
    label: "Saint Lucia",
    value: "Saint Lucia",
    countryId: 186,
  },
  {
    _id: "63df3ef033c398319d2ec474",
    label: "San Marino",
    value: "San Marino",
    countryId: 192,
  },
  {
    _id: "63df3ef033c398319d2ec46c",
    label: "Saint Vincent And The Grenadines",
    value: "Saint Vincent And The Grenadines",
    countryId: 188,
  },
  {
    _id: "63df3ef033c398319d2ec470",
    label: "Saint-Martin (French part)",
    value: "Saint-Martin (French part)",
    countryId: 190,
  },
  {
    _id: "63df3ef033c398319d2ec482",
    label: "Singapore",
    value: "Singapore",
    countryId: 199,
  },
  {
    _id: "63df3ef033c398319d2ec47e",
    label: "Seychelles",
    value: "Seychelles",
    countryId: 197,
  },
  {
    _id: "63df3ef033c398319d2ec46e",
    label: "Saint-Barthelemy",
    value: "Saint-Barthelemy",
    countryId: 189,
  },
  {
    _id: "63df3ef033c398319d2ec488",
    label: "Slovenia",
    value: "Slovenia",
    countryId: 201,
  },
  {
    _id: "63df3ef033c398319d2ec48a",
    label: "Solomon Islands",
    value: "Solomon Islands",
    countryId: 202,
  },
  {
    _id: "63df3ef033c398319d2ec486",
    label: "Slovakia",
    value: "Slovakia",
    countryId: 200,
  },
  {
    _id: "63df3ef033c398319d2ec47c",
    label: "Serbia",
    value: "Serbia",
    countryId: 196,
  },
  {
    _id: "63df3ef033c398319d2ec478",
    label: "Saudi Arabia",
    value: "Saudi Arabia",
    countryId: 194,
  },
  {
    _id: "63df3ef033c398319d2ec484",
    label: "Sint Maarten (Dutch part)",
    value: "Sint Maarten (Dutch part)",
    countryId: 250,
  },
  {
    _id: "63df3ef033c398319d2ec48e",
    label: "South Africa",
    value: "South Africa",
    countryId: 204,
  },
  {
    _id: "63df3ef033c398319d2ec47a",
    label: "Senegal",
    value: "Senegal",
    countryId: 195,
  },
  {
    _id: "63df3ef033c398319d2ec472",
    label: "Samoa",
    value: "Samoa",
    countryId: 191,
  },
  {
    _id: "63df3ef033c398319d2ec480",
    label: "Sierra Leone",
    value: "Sierra Leone",
    countryId: 198,
  },
  {
    _id: "63df3ef033c398319d2ec48c",
    label: "Somalia",
    value: "Somalia",
    countryId: 203,
  },
  {
    _id: "63df3ef033c398319d2ec476",
    label: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
    countryId: 193,
  },
  {
    _id: "63df3ef033c398319d2ec3d5",
    label: "Malta",
    value: "Malta",
    countryId: 135,
  },
  {
    _id: "63df3ef033c398319d2ec3dc",
    label: "Martinique",
    value: "Martinique",
    countryId: 138,
  },
  {
    _id: "63df3ef033c398319d2ec49e",
    label: "Suriname",
    value: "Suriname",
    countryId: 210,
  },
  {
    _id: "63df3ef033c398319d2ec496",
    label: "Spain",
    value: "Spain",
    countryId: 207,
  },
  {
    _id: "63df3ef033c398319d2ec494",
    label: "South Sudan",
    value: "South Sudan",
    countryId: 206,
  },
  {
    _id: "63df3ef033c398319d2ec49c",
    label: "Sudan",
    value: "Sudan",
    countryId: 209,
  },
  {
    _id: "63df3ef033c398319d2ec4a0",
    label: "Svalbard And Jan Mayen Islands",
    value: "Svalbard And Jan Mayen Islands",
    countryId: 211,
  },
  {
    _id: "63df3ef033c398319d2ec49a",
    label: "Sri Lanka",
    value: "Sri Lanka",
    countryId: 208,
  },
  {
    _id: "63df3ef033c398319d2ec4a8",
    label: "Syria",
    value: "Syria",
    countryId: 215,
  },
  {
    _id: "63df3ef033c398319d2ec4a4",
    label: "Sweden",
    value: "Sweden",
    countryId: 213,
  },
  {
    _id: "63df3ef033c398319d2ec4a2",
    label: "Swaziland",
    value: "Swaziland",
    countryId: 212,
  },
  {
    _id: "63df3ef033c398319d2ec4aa",
    label: "Taiwan",
    value: "Taiwan",
    countryId: 216,
  },
  {
    _id: "63df3ef033c398319d2ec4b2",
    label: "The Bahamas",
    value: "The Bahamas",
    countryId: 17,
  },
  {
    _id: "63df3ef033c398319d2ec4ac",
    label: "Tajikistan",
    value: "Tajikistan",
    countryId: 217,
  },
  {
    _id: "63df3ef033c398319d2ec4a6",
    label: "Switzerland",
    value: "Switzerland",
    countryId: 214,
  },
  {
    _id: "63df3ef033c398319d2ec4b0",
    label: "Thailand",
    value: "Thailand",
    countryId: 219,
  },
  {
    _id: "63df3ef033c398319d2ec4ae",
    label: "Tanzania",
    value: "Tanzania",
    countryId: 218,
  },
  {
    _id: "63df3ef033c398319d2ec4b6",
    label: "Tokelau",
    value: "Tokelau",
    countryId: 221,
  },
  {
    _id: "63df3ef033c398319d2ec4b4",
    label: "Togo",
    value: "Togo",
    countryId: 220,
  },
  {
    _id: "63df3ef033c398319d2ec4b8",
    label: "Tonga",
    value: "Tonga",
    countryId: 222,
  },
  {
    _id: "63df3ef033c398319d2ec4bc",
    label: "Tunisia",
    value: "Tunisia",
    countryId: 224,
  },
  {
    _id: "63df3ef033c398319d2ec4ba",
    label: "Trinidad And Tobago",
    value: "Trinidad And Tobago",
    countryId: 223,
  },
  {
    _id: "63df3ef033c398319d2ec4be",
    label: "Turkey",
    value: "Turkey",
    countryId: 225,
  },
  {
    _id: "63df3ef033c398319d2ec4c8",
    label: "Ukraine",
    value: "Ukraine",
    countryId: 230,
  },
  {
    _id: "63df3ef033c398319d2ec4c2",
    label: "Turks And Caicos Islands",
    value: "Turks And Caicos Islands",
    countryId: 227,
  },
  {
    _id: "63df3ef033c398319d2ec4cf",
    label: "United Kingdom",
    value: "United Kingdom",
    countryId: 232,
  },
  {
    _id: "63df3ef033c398319d2ec4c6",
    label: "Uganda",
    value: "Uganda",
    countryId: 229,
  },
  {
    _id: "63df3ef033c398319d2ec4c0",
    label: "Turkmenistan",
    value: "Turkmenistan",
    countryId: 226,
  },
  {
    _id: "63df3ef033c398319d2ec4c4",
    label: "Tuvalu",
    value: "Tuvalu",
    countryId: 228,
  },
  {
    _id: "63df3ef033c398319d2ec4cd",
    label: "United Arab Emirates",
    value: "United Arab Emirates",
    countryId: 231,
  },
  {
    _id: "63df3ef033c398319d2ec4ef",
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands",
    countryId: 234,
  },
  {
    _id: "63df3ef033c398319d2ec4f2",
    label: "Uruguay",
    value: "Uruguay",
    countryId: 235,
  },
  {
    _id: "63df3ef033c398319d2ec4d1",
    label: "United States",
    value: "United States",
    countryId: 233,
  },
  {
    _id: "63df3ef033c398319d2ec4f4",
    label: "Uzbekistan",
    value: "Uzbekistan",
    countryId: 236,
  },
  {
    _id: "63df3ef033c398319d2ec4fb",
    label: "Venezuela",
    value: "Venezuela",
    countryId: 239,
  },
  {
    _id: "63df3ef033c398319d2ec4f9",
    label: "Vatican City State (Holy See)",
    value: "Vatican City State (Holy See)",
    countryId: 238,
  },
  {
    _id: "63df3ef033c398319d2ec4f7",
    label: "Vanuatu",
    value: "Vanuatu",
    countryId: 237,
  },
  {
    _id: "63df3ef033c398319d2ec503",
    label: "Wallis And Futuna Islands",
    value: "Wallis And Futuna Islands",
    countryId: 243,
  },
  {
    _id: "63df3ef033c398319d2ec4fd",
    label: "Vietnam",
    value: "Vietnam",
    countryId: 240,
  },
  {
    _id: "63df3ef033c398319d2ec501",
    label: "Virgin Islands (US)",
    value: "Virgin Islands (US)",
    countryId: 242,
  },
  {
    _id: "63df3ef033c398319d2ec507",
    label: "Yemen",
    value: "Yemen",
    countryId: 245,
  },
  {
    _id: "63df3ef033c398319d2ec4ff",
    label: "Virgin Islands (British)",
    value: "Virgin Islands (British)",
    countryId: 241,
  },
  {
    _id: "63df3ef033c398319d2ec505",
    label: "Western Sahara",
    value: "Western Sahara",
    countryId: 244,
  },
  {
    _id: "63df3ef033c398319d2ec509",
    label: "Zambia",
    value: "Zambia",
    countryId: 246,
  },
  {
    _id: "63df3ef033c398319d2ec50b",
    label: "Zimbabwe",
    value: "Zimbabwe",
    countryId: 247,
  },
  {
    _id: "63df3ef033c398319d2ec490",
    label: "South Georgia",
    value: "South Georgia",
    countryId: 205,
  },
  {
    _id: "63df3ef033c398319d2ec492",
    label: "South Korea",
    value: "South Korea",
    countryId: 116,
  },
];
