import {
  DEPARTMENTS_FETCHED,
  DEFAULT_DEPARTMENTS_FETCHED,
} from './departmentsTypes';

export const getDepartmentsAction = (data) => (dispatch) => {
  dispatch({
    type: DEPARTMENTS_FETCHED,
    payload: data,
  });
};

export const getDefaultDepartmentsAction = (data) => (dispatch) => {
  dispatch({
    type: DEFAULT_DEPARTMENTS_FETCHED,
    payload: data,
  });
};
