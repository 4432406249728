import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Box from "@mui/system/Box";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";

function Schedules() {
  return (
    <Container maxWidth="lg">
      <Stack sx={{ textAlign: "center", marginY: 35 }}>
        <Box sx={{ paddingX: 20 }}>
          <TimerOutlinedIcon fontSize="large" color="disabled" />
          <Typography variant="h2" sx={{ color: "lightgray" }}>
            Coming soon !
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
}

export default Schedules;
