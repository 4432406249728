import { useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";

import MobileEmail from "./MobileEmail";
import DDLogo from "../icons/ddLogo";
import MobileEmailOtp from "./MobileEmailOtp";
import SelectHospital from "./SelectHospital";
import api from "../../utilities/api";
import setAuthToken from "../../utilities/setAuthToken";
import {
  loginSuccessAction,
  loginFailureAction,
  loadHospitalProfilesAction,
} from "../../redux/auth/authAction";
import {
  loadObUserAction,
  loadObHospitalAction,
  loadObuserProfileAction,
} from "../../redux/onboarding/onboardingAction";
import { countries } from "../../utilities/countries";

const StyledLoginPaper = styled(
  Paper,
  {}
)({
  width: 250,
  height: 350,
  borderRadius: 2,
  // to setup up background image use following syntax
  backgroundImage: `url('https://picsum.photos/300/300') `,
});

const pages = ["Enter Mobile Or Email", "Enter OTP"];

const initialValues = {
  me: "",
  country: countries[103],
  otp: "",
  password: "",
};

const mobileEmailValidation = yup.object({
  me: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

const otpValidation = yup.object({
  otp: yup.string().required("Required"),
});

const validationSchema = [mobileEmailValidation, otpValidation];

export default function Login() {
  const [manyProfiles, setmanyProfiles] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  async function getGeoInfo() {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      if (response) {
        const index = countries
          .map((object) => object.code)
          .indexOf(response.data.country_code);
        formik.setFieldValue("country", countries[index]);
      }
    } catch (err) {
      if (err) formik.setFieldValue("country", countries[103]);
    }
  }

  useEffect(() => {
    getGeoInfo();
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const { isAuthenticated, isAuthenticatedToSeeHospitalProfiles, hospital } =
    auth;

  const onboarding = useSelector((state) => state.onboarding);
  const { isObAuthenticated, obUser } = onboarding;

  const isLastPage = currentStep === pages.length - 1;
  const backStep = (step) => {
    setCurrentStep(step - 1);
  };

  function getEmailOrMobileObj(mobileOrEmail, pass, code) {
    const isText = isNaN(mobileOrEmail);
    if (isText) {
      return {
        email: mobileOrEmail,
        password: pass,
        type: "web",
      };
    } else {
      return {
        mobile: mobileOrEmail,
        countryCode: code,
        password: pass,
        type: "web",
      };
    }
  }

  const onSubmit = async (values, actions) => {
    const { me, password, country } = values;
    const formData = {
      user: getEmailOrMobileObj(me, password, country.phone),
    };
    if (currentStep === 0) {
      try {
        const response = await api.post("/auth/login", formData);
        const { data } = response.data;
        const { token, hospital } = data;
        const { userProfile, multipleProfile } = hospital;
        setAuthToken(token);
        // check if OTP required. For now lets assume we dont need it.
        let OtpRequired = false;
        if (response && OtpRequired) {
          actions.setTouched({});
          actions.setSubmitting(false);
          setCurrentStep(currentStep + 1);
        }

        if (response && !OtpRequired) {
          if (!multipleProfile && userProfile?.webOnboarded) {
            dispatch(loginSuccessAction(data));
            navigate("/dashboard");
          }
          if (!multipleProfile && !userProfile?.webOnboarded) {
            dispatch(loadObUserAction(data?.hospital?.user));
            // if (data?.hospital) {
            //   dispatch(loadObHospitalAction(data?.hospital));
            // }
            // if (data?.hospital?.userProfile) {
            //   dispatch(loadHospitalProfilesAction(data?.hospital?.userProfile));
            // }
            navigate("/edit-onboarding");
          }

          if (multipleProfile) {
            setmanyProfiles(multipleProfile);
            dispatch(loadHospitalProfilesAction(data));
          }
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("password", err.response?.data.message);
          dispatch(loginFailureAction());
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema[currentStep],
    onSubmit: onSubmit,
  });

  function renderPageContent(step) {
    switch (step) {
      case 0:
        return <MobileEmail formik={formik} />;
      case 1:
        return <MobileEmailOtp backStep={backStep} formik={formik} />;

      default:
        return <div>Page Not Found</div>;
    }
  }

  // if (
  //   isAuthenticated &&
  //   multipleProfile === 1 &&
  //   hospital.userProfile.webOnboarded
  // ) {
  //   return <Navigate to='/dashboard' />;
  // }

  // if (
  //   isAuthenticated &&
  //   multipleProfile === 1 &&
  //   !hospital.userProfile.webOnboarded
  // ) {
  //   return <Navigate to='/onboarding' />;
  // }

  return (
    <>
      {isAuthenticatedToSeeHospitalProfiles && manyProfiles ? (
        <SelectHospital />
      ) : (
        <>
          {isAuthenticated &&
            !manyProfiles &&
            hospital.userProfile?.webOnboarded && (
              <Navigate to="/dashboard" replace={true} />
            )}
          {isObAuthenticated && obUser && (
            <Navigate to="/edit-onboarding" replace={true} />
          )}
          <Box
            sx={{
              display: "block",
              width: 890,
              minHeight: 520,
              // height: 520,
              backgroundColor: "#fff",
              boxShadow: "0px 2px 30px #ccc6",
              margin: "auto",
              marginTop: "7%",
              overflow: "hidden",
            }}
          >
            <>
              <Box
                p={4}
                sx={{
                  width: 500,
                  minHeight: 520,
                  height: "auto",
                  background: "#fff",
                  padding: 6,
                  borderRadius: 5,
                  transition: "all .1s ease-in-out",
                  float: "left",
                  overflowY: "auto",
                  display: "table-cell",
                  borderRight: "2px solid #f1f1f1",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "& > :not(style)": {
                      mr: 2,
                      fontSize: "10",
                    },
                  }}
                >
                  <DDLogo inheritViewBox />
                  <Typography variant="h6">Daily Doc</Typography>
                </Box>
                <Box
                  sx={{
                    marginBottom: 3,
                    marginTop: 2,
                  }}
                >
                  <Typography variant="h6">Login</Typography>
                </Box>
                <Box
                  sx={{
                    marginBottom: 10,
                    marginTop: 5,
                  }}
                >
                  <form onSubmit={formik.handleSubmit} autoComplete="off">
                    {renderPageContent(currentStep)}
                    <Box
                      sx={{
                        display: "flex",
                        marginTop: 3,
                        marginBotton: 3,
                        gap: 2,
                      }}
                    >
                      {isLastPage && currentStep > 0 && (
                        <Button
                          variant="outlined"
                          fullWidth
                          size="large"
                          type="button"
                          onClick={() => backStep(currentStep)}
                          className="button button-green"
                        >
                          Back
                        </Button>
                      )}
                      <Box sx={{ width: "100%", position: "relative" }}>
                        <Button
                          variant="contained"
                          fullWidth
                          size="large"
                          type="submit"
                          disabled={formik.isSubmitting}
                        >
                          {isLastPage ? "Submit" : "Next"}
                        </Button>
                        {formik.isSubmitting && (
                          <CircularProgress
                            size={24}
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                              zIndex: 1,
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </form>
                </Box>
                <Box
                  sx={{
                    marginTop: 5,
                    textAlign: "center",
                    color: "primary",
                  }}
                >
                  <Link
                    href="/forgot-password"
                    color="primary"
                    variant="body2"
                    underline="hover"
                  >
                    Forgot password?
                  </Link>
                  <Box
                    sx={{
                      ...(currentStep > 0 && { display: "none" }),
                    }}
                  >
                    <Typography component="span" variant="body2">
                      Do not have account?
                    </Typography>
                    <Link
                      href="/onboarding"
                      m={1}
                      color="primary"
                      variant="body2"
                      underline="hover"
                    >
                      Signup
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: 390,
                  minHeight: 520,
                  height: "auto",
                  float: "right",
                  padding: 6,
                  backgroundColor: "#fff",
                  display: "table-cell",
                  overflow: "hidden",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <StyledLoginPaper />
                </Box>
              </Box>
            </>
          </Box>
        </>
      )}
    </>
  );
}
