import api from "../../utilities/api";
import { loadAccessControlDataAction } from "../../redux/acess-control/AccessControlAction";
import store from "../../redux/store";

export const getAccessControlData = async (hospitalId) => {
  const url = `/hospitals/${hospitalId}/users?type=accesscontrol`;
  const response = await api.get(url);
  if (response)
    store.dispatch(loadAccessControlDataAction(response.data.data.users));
};
