import { EMPLOYEES_LOADED, LOAD_DEFAULT_EMPLOYEE_DATA } from './employeesTypes';
const initialState = {
  isLoading: true,
  isError: false,
  employeesData: [],
  defaultRoles: [],
  defaultDesignations: [],
  hospitalDepartments: [],
};

export default function employeesReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case EMPLOYEES_LOADED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        employeesData: payload,
      };
    case LOAD_DEFAULT_EMPLOYEE_DATA:
      return {
        ...state,
        defaultRoles: payload.hospitalRole,
        defaultDesignations: payload.designations,
        hospitalDepartments: payload.departments,
      };

    default:
      return {
        ...state,
      };
  }
}
