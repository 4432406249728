import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { Upload as UploadIcon } from "../icons/Upload";
import AddBedForm from "./BedsForm";
import { getWardsData } from "../wards/utilWards";

export const BedsToolbar = () => {
  const hospital = useSelector((state) => state.auth.hospital);
  const { isLoading, isError, wards } = useSelector((state) => state.wards);
  useEffect(() => {
    getWardsData(hospital?._id);
    return () => {};
  }, [hospital]);
  const navigate = useNavigate();
  return (
    <Box sx={{ marginTop: 2 }}>
      <Grid container spacing={{ xs: 2 }}>
        {/* <Grid item xs={12} sm={3} sx={{ height: 200 }}>
          <Card
            sx={{
              height: "100%",
              width: "100%",
              minWidth: 200,
              textAlign: "center",
              padding: 2,
            }}
          >
            <CardHeader
              title="Bulk Upload Beds"
              subheader="Add multiple beds"
              sx={{ py: 3, px: 2 }}
            />
            <Box sx={{ marginBottom: 2, minHeight: 40 }}>
              <Button
                variant="contained"
                startIcon={<UploadIcon />}
                onClick={() => {
                  navigate("/dashboard/upload-beds-csv");
                }}
              >
                Upload CSV
              </Button>
            </Box>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={12} sx={{ height: 300 }}>
          <Card
            sx={{
              height: "100%",
              width: "100%",
              padding: 2,
            }}
          >
            <CardHeader
              title="Add one bed at a time"
              subheader="In each ward, add beds one by one. At ward level, the bed name has to be uniquie. There could be same bed name but in different wards. Only wards created in wards section will show in wards dropdown. If you dont see a ward in the dropdown, please create that in wards section first.  "
              sx={{ py: 3, px: 2 }}
            />
            <Box sx={{ marginBottom: 2, px: 2, minHeight: 40 }}>
              {!isLoading && !isError && <AddBedForm wards={wards} />}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BedsToolbar;
