import Login from '../../components/login/Login';
function LoginPage() {
  return (
    <>
      <Login />
    </>
  );
}

export default LoginPage;
