import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import Timer from "../timer/Timer";

function MobileOtp({ formik, resetMobile, resendMobileOtp }) {
  return (
    <>
      <Stack spacing={4}>
        <Box
          sx={{
            border: "1px solid #E6E8F0",
            height: 56,
            borderRadius: 1,
            backgroundColor: "background.default",
            display: "flex",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Typography sx={{ flexGrow: 1 }}>{formik?.values?.phone}</Typography>
          <IconButton
            // size='small'
            sx={{ mx: 1 }}
            onClick={resetMobile}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </Box>
        <TextField
          fullWidth
          id="mobileOtp"
          name="mobileOtp"
          label="Mobile OTP"
          value={formik.values.mobileOtp}
          onChange={formik.handleChange}
          error={formik.touched.mobileOtp && Boolean(formik.errors.mobileOtp)}
          helperText={formik.touched.mobileOtp && formik.errors.mobileOtp}
          inputProps={{ maxLength: 6 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockOpenIcon />
              </InputAdornment>
            ),
          }}
        />
        <Timer resendOtp={resendMobileOtp} />
      </Stack>
    </>
  );
}

export default MobileOtp;
