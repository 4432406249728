import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";
import SubSubMenu from "../../components/navbar/SubSubMenu";

export default function ProfileLayout() {
  const manageProfileSubmenu = [
    { label: "Profile", path: "/dashboard/profile" },
    { label: "Hospitals", path: "/dashboard/profile/hospitals" },
  ];
  return (
    <Container sx={{ display: "flex" }} maxWidth="xl">
      <Box>
        <SubSubMenu menuList={manageProfileSubmenu} />
      </Box>
      <Box px={2} sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Container>
  );
}
