import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import PageHeading from "../../components/reusables/PageHeading";
import AccessControlCsvTable from "../../components/access-control-csv/AccessControlCsvTable";
import CSVReader from "../../components/access-control-csv/CSVReader";

import AccessControlAction from "../../components/access-control/AccessControlAction";

function isEmpty(object) {
  for (const property in object) {
    return false;
  }
  return true;
}

function AccessControlCsvPage() {
  const navigate = useNavigate();
  const [CSVReaderData, setCSVReaderData] = useState([]);

  return (
    <Container>
      <PageHeading heading="Upload csv file" action={<AccessControlAction />} />
      <CSVReader setCSVReaderData={setCSVReaderData} />
      <AccessControlCsvTable
        CSVReaderData={CSVReaderData}
        setCSVReaderData={setCSVReaderData}
      />
    </Container>
  );
}

export default AccessControlCsvPage;
