import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function InstructionItem({ title, subtitle, steps, number }) {
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={{ backgroundColor: "primary.main" }}>{number}</Avatar>
        </ListItemAvatar>
        <Box>
          <Typography variant="h6">{title}</Typography>
          <Box>
            <Typography mb={2} variant="subtitle2">
              {subtitle}
            </Typography>
            {steps}
          </Box>
        </Box>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}
