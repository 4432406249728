import ChangePassword from '../../components/change-password/ChangePassword';
function ChangePasswordPage() {
  return (
    <>
      <ChangePassword />
    </>
  );
}

export default ChangePasswordPage;
