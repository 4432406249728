import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  Checkbox,
  Autocomplete,
  Stack,
  Modal,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { useFormik } from "formik";

import AddIcon from "@mui/icons-material/Add";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { loadAllAdminsAction } from "../../redux/admins/adminAction";
import { showSnackbarAction } from "../../redux/alert/alertAction";
import { getDepartmentsData } from "../departments/utilDepartments";
import api from "../../utilities/api";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledForm = styled("form")({
  width: "100%",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "60%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  px: 4,
  py: 8,
};

const EditAdminForm = ({ showEdit, editData, handleClose }) => {
  const [adminPanelRoles, setadminPanelRoles] = useState([]);
  const hospital = useSelector((state) => state.auth.hospital);
  const dispatch = useDispatch();

  const { departmentsList } = useSelector((state) => state.departments);

  const onSubmit = async (values) => {
    const formData = {
      user: {
        aclRole: values.adminPanelRole._id,
        departments: values.departments,
      },
    };

    try {
      let response = await api.patch(
        `hospitals/${hospital._id}/access/users/${editData._id}`,
        formData
      );
      dispatch(loadAllAdminsAction(response.data.data.users));
      dispatch(showSnackbarAction(response.data.message, "success"));
      handleClose();
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, "error"));
    }
  };

  const initialValues = {
    adminPanelRole:
      adminPanelRoles.find((e) => e.name === editData?.role) || null,
    department: editData?.departments || null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  async function getAdminPanelRoles() {
    try {
      const response = await api.get("/acl/roles?webAccess=true");
      if (response) setadminPanelRoles(response.data.data);
    } catch (err) {
      dispatch(showSnackbarAction(err.response.data.message, "error"));
    }
  }

  const isAdmin = () => {
    if (formik.values.adminPanelRole === "613b5f785c768a51d90f295c") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getAdminPanelRoles();
    getDepartmentsData(hospital._id);
  }, [hospital]);

  return (
    <>
      <Modal
        open={showEdit}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StyledForm onSubmit={formik.handleSubmit}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 24,
              }}
            >
              <SupervisorAccountIcon
                sx={{ fontSize: 40, marginRight: 2 }}
                color="secondary"
              />
              <Typography variant="h6">Edit Admin</Typography>
            </div>
            <Stack spacing={4}>
              <Autocomplete
                value={formik.values.adminPanelRole}
                id="adminPanelRole"
                name="adminPanelRole"
                options={adminPanelRoles}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  formik.setFieldValue("adminPanelRole", newValue);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Role"
                    placeholder="Role"
                    error={
                      formik.touched.adminPanelRole &&
                      Boolean(formik.errors.adminPanelRole)
                    }
                    helperText={
                      formik.touched.adminPanelRole &&
                      formik.errors.adminPanelRole
                    }
                  />
                )}
              />

              <Autocomplete
                multiple
                disabled={isAdmin()}
                value={formik.values.departments}
                id="departments"
                name="departments"
                defaultValue={editData?.departments}
                options={departmentsList}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  formik.setFieldValue("departments", newValue);
                }}
                style={{
                  display: isAdmin() ? "none" : null,
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Departments"
                    placeholder="Search Department"
                    error={
                      formik.touched.department &&
                      Boolean(formik.errors.department)
                    }
                    helperText={
                      formik.touched.department && formik.errors.department
                    }
                  />
                )}
              />
              <Button
                sx={{ float: "right" }}
                type="submit"
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
              >
                Update
              </Button>
            </Stack>
          </StyledForm>
        </Box>
      </Modal>
    </>
  );
};

export default EditAdminForm;
