import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import api from "../../utilities/api";
import { getWardsData } from "./utilWards";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";

const StyledForm = styled("form")({
  //   display: 'flex',
  //   alignItems: 'center',
  width: "100%",
  //   justifyContent: 'space-between',
  //   // '.MuiTextField-root': { marginTop: '0' },
  //   // '.MuiButton-root': { float: 'right' },
  //   '.MuiAutocomplete-root': { width: 200, marginRight: 16 },
  //   '.MuiTextField-root': { marginTop: 0, marginBottom: 0 },
});

const initialValues = {
  ward: "",
};

const validation = yup.object({
  ward: yup.string().required("Required"),
});

function WardsForm() {
  const hospital = useSelector((state) => state.auth.hospital);

  const onSubmit = async (values, actions) => {
    const formData = {
      hospital: hospital._id,
      wards: [
        {
          name: values.ward,
        },
      ],
    };
    try {
      const url = `/hospitals/${hospital._id}/wards`;
      const response = await api.post(url, formData);
      if (response) {
        getWardsData(hospital._id);
        actions.resetForm({
          ward: "",
        });
        actions.setStatus({ success: true });
      }
    } catch (err) {
      if (err) {
        actions.setStatus({ success: false });
        actions.setFieldError("ward", err.response.data.message);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: onSubmit,
  });

  return (
    <>
      <Card sx={{ marginTop: 2 }}>
        <CardHeader
          title="Add one ward/floor at a time"
          subheader="Each ward has to be unique"
          sx={{ py: 3, px: 2 }}
        />
        <Box sx={{ marginBottom: 2, px: 2 }}>
          <StyledForm
            className="ward-form"
            onSubmit={formik.handleSubmit}
            autoComplete="off"
          >
            <Grid container spacing={{ xs: 1 }}>
              <Grid item xs={12} sm={6} lg={5}>
                <TextField
                  // sx={{ marginRight: 1 }}
                  size="small"
                  fullWidth
                  id="ward"
                  name="ward"
                  label="Ward"
                  autoComplete="off"
                  autoCapitalize="off"
                  autoCorrect="off"
                  value={formik.values.ward}
                  onChange={formik.handleChange}
                  error={formik.touched.ward && Boolean(formik.errors.ward)}
                  helperText={formik.touched.ward && formik.errors.ward}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={7}>
                <Box>
                  <Button
                    sx={{ float: "right" }}
                    type="submit"
                    startIcon={<AddIcon />}
                    color="primary"
                    variant="contained"
                    disabled={formik.isSubmitting}
                  >
                    Add
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </StyledForm>
        </Box>
      </Card>
    </>
  );
}

export default WardsForm;
