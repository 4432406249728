import api from '../../utilities/api';

import {
  loadEmployeesAction,
  loadDefaultEmployeeDataAction,
} from '../../redux/employees/employeesAction';
import store from '../../redux/store';

export const getEmployeeData = async (hospitalId) => {
  const url = `/hospitals/${hospitalId}/users?type=onboarded`;
  const response = await api.get(url);
  if (response) store.dispatch(loadEmployeesAction(response.data.data.users));
};

export const getDefaultDataForEmployees = async (hospitalId) => {
  const url = `/common/hospitals/${hospitalId}/er1/employees`;
  const response = await api.get(url);
  if (response) {
    store.dispatch(loadDefaultEmployeeDataAction(response.data.data));
  }
};
