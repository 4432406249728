import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { parsePhoneNumber } from "react-phone-number-input";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import MobilePage from "../reusables/MobilePage";
import MobileOtp from "./MobileOtp";
import EmailPage from "./EmailPage";
import EmailOtp from "./EmailOtp";
import PasswordPage from "./PasswordPage";
import HospitalPage from "./HospitalPage";
import DepartmentsPage from "./DepartmentsPage";
import OnboardingSuccess from "./OnboardingSuccess";
import ObWardsPage from "./ObWardsPage";
import ObBedsPage from "./ObBedsPage";

import api from "../../utilities/api";
import setAuthToken from "../../utilities/setAuthToken";
import ExitDialog from "./ExitDialog";

import {
  loadObUserAction,
  loadObHospitalAction,
  loadObuserProfileAction,
  resetOnboardingAction,
} from "../../redux/onboarding/onboardingAction";
import { logoutAction } from "../../redux/auth/authAction";
import {
  removeCountryListAction,
  removeStateListAction,
  removeCityListAction,
} from "../../redux/list/listAction";
import { validationSchema } from "./validationSchema";

const PaperElement = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  width: 1000,
  padding: 50,
  ".stepper-form": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: 'red',
    justifyContent: "space-between",
  },
}));

// No of headings in this array will be equal to numb of  actual steps required to complete form
// its different from steppers steps

const steps = [
  "Enter Mobile",
  "Mobile OTP",
  "Provide Email",
  "Email OTP",
  "Set Password",
  "User and Hospital Details",
  "Choose Departments",
  "Add Wards",
  "Add Beds",
];

// No of headings actually show the steps diaplayed in stepper

const headings = [
  "Login Details",
  "User & Hospital Details",
  "Choose Departments",
  "Add Wards",
  "Add Beds",
];

const HospitalOnboarding = () => {
  const [defaultCountry, setdefaultCountry] = useState("IN");
  const [activeStep, setactiveStep] = useState(0);
  const [activeHeading, setactiveHeading] = useState(0);
  const isLastStep = activeStep === steps.length - 1;
  const [OTPid, setOTPid] = useState("");
  const [open, setOpen] = useState(false);

  // for calling action creator
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { obUser, obHospital, obUserProfile } = useSelector(
    (state) => state.onboarding
  );

  // function for going to the back page.
  function handleBack(step, heading) {
    if (step > 5) {
      setactiveStep(step - 1);
      setactiveHeading(heading - 1);
    }
  }

  const onSubmit = async (values, actions) => {
    console.log("active step", activeStep);

    if (activeStep === 0) {
      const phoneNumber = parsePhoneNumber(values.phone);
      console.log(`form data from step ${activeStep} : `, values);
      const formData = {
        user: {
          countryCode: phoneNumber?.countryCallingCode,
          mobile: phoneNumber?.nationalNumber,
          rawMobile: phoneNumber?.number,
        },
      };
      try {
        const response = await api.post("/auth/ob0", formData);
        console.log(`API call step ${activeStep}: `, response);
        if (response) {
          setOTPid(response.data.data._id);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("phone", err.response?.data.message);
        }
      }
    }

    if (activeStep === 1) {
      let { mobileOtp } = values;
      const formData = {
        otpData: {
          _id: OTPid,
          otp: mobileOtp,
          type: "mobile",
        },
      };

      try {
        const response = await api.post("/auth/ob0/verify/otp", formData);
        console.log(`API call step ${activeStep}: `, response);
        const { data } = response.data;
        const obUser = data.user;
        if (response) {
          setAuthToken(response.data.data.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          dispatch(loadObUserAction(obUser));
          setactiveStep(activeStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("mobileOtp", err.response?.data.message);
        }
      }
    }

    if (activeStep === 2) {
      const { email } = values;
      const formData = {
        user: {
          email,
          user: obUser?._id,
        },
      };

      try {
        const response = await api.post("/auth/ob1", formData);
        console.log(`API call step ${activeStep}: `, response);
        if (response) {
          setOTPid(response.data.data._id);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("email", err.response?.data.message);
        }
      }
    }

    if (activeStep === 3) {
      let { emailOtp } = values;

      const formData = {
        otpData: {
          _id: OTPid,
          otp: emailOtp,
          type: "email",
        },
      };

      try {
        const response = await api.post("/auth/ob1/verify/otp", formData);
        console.log(`API call step ${activeStep}: `, response);
        if (response) {
          setAuthToken(response.data.data.token);
          dispatch(loadObUserAction(response.data.data.user));
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("emailOtp", err.response?.data.message);
        }
      }
    }

    if (activeStep === 4) {
      const { password, confirmPassword } = values;
      const formData = {
        user: {
          _id: obUser._id,
          userPassword: password,
          confirmPassword,
        },
      };

      try {
        const response = await api.post("/onboarding/ob2", formData);
        console.log(`API call step ${activeStep}: `, response);
        if (response) {
          console.log("token ob", response.data.data.token);
          setAuthToken(response.data.data.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("password", err.response?.data.message);
        }
      }
    }

    if (activeStep === 5) {
      const {
        firstName,
        lastName,
        hospitalName,
        hospitalShortName,
        country,
        state,
        city,
      } = values;

      const formData = {
        user: {
          _id: obUser._id,
          firstName,
          lastName,
        },
        hospital: {
          _id: obHospital?._id || "",
          name: hospitalName,
          code: hospitalShortName,
          country: country.value,
          state: state.value,
          city: city.value,
        },
      };
      console.log("hospital data ", formData);
      try {
        const response = await api.post("/onboarding/ob3", formData);
        console.log(`API call step ${activeStep}: `, response);
        if (response) {
          setAuthToken(response.data.data.token);
          dispatch(loadObUserAction(response.data.data.user));
          dispatch(loadObHospitalAction(response.data.data.hospital));
          dispatch(loadObuserProfileAction(response.data.data.userProfile));
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("firstName", err.response?.data.message);
        }
      }
    }
    if (activeStep === 6) {
      let { departments } = values;
      const formData = {
        hospital: obHospital?._id,
        departments,
        user: obUser?._id,
        userProfile: obUserProfile?._id,
      };
      try {
        const response = await api.post("/onboarding/ob4", formData);
        console.log(`API call step ${activeStep}: `, response);
        if (response) {
          setAuthToken(response.data.data.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("departments", err.response?.data.message);
        }
      }
    }

    if (activeStep === 7) {
      let { wards } = values;
      const formData = {
        hospital: obHospital?._id,
        user: obUser?._id,
        userProfile: obUserProfile?._id,
        wards: wards,
      };

      try {
        const response = await api.post("/onboarding/ob5", formData);
        console.log(`API call step ${activeStep}: `, response);
        if (response) {
          actions.setFieldValue("wards", response.data.data.savedWards);

          // update beds arry
          if (values.beds?.length > 0) {
            const newBeds = values.beds?.map((item) => {
              const updatedBed = response.data.data.savedWards.find(
                (i2) => i2.uid === item.wardUid
              );
              return updatedBed
                ? {
                    ...item,
                    wardId: updatedBed?._id,
                    wardName: updatedBed.name,
                  }
                : item;
            });
            actions.setFieldValue("beds", newBeds);
          }

          setAuthToken(response.data.data.token);
          actions.setTouched({});
          actions.setSubmitting(false);
          setactiveStep(activeStep + 1);
          setactiveHeading(activeHeading + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("wards", err.response?.data.message);
        }
      }
    }

    if (activeStep === 8) {
      let { beds } = values;
      const formData = {
        hospital: obHospital?._id,
        user: obUser?._id,
        userProfile: obUserProfile?._id,
        wardBeds: beds,
      };

      try {
        const response = await api.post("/onboarding/ob6", formData);
        console.log(`API call step ${activeStep}: `, response);
        if (response) {
          setactiveStep(activeStep + 1);
        }
      } catch (err) {
        if (err) {
          actions.setFieldError("beds", err.response?.data.message);
        }
      }
    }
  };

  // onSubmit ends

  const initialValues = {
    phone: "",
    mobileOtp: "",
    email: "",
    emailOtp: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    hospitalName: "",
    hospitalShortName: "",
    country: null,
    state: null,
    city: null,
    departments: [],
    ward: "",
    wards: [],
    selectedWard: null,
    bed: "",
    beds: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema[activeStep],
    onSubmit: onSubmit,
  });

  const resetMobile = () => {
    formik.resetForm({
      values: initialValues,
    });
    setactiveStep(0);
    setactiveHeading(0);
  };

  const resetEmail = () => {
    const newValues = {
      phone: formik.values.phone,
      mobileOtp: "",
      email: "",
      emailOtp: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      hospitalName: "",
      hospitalShortName: "",
      country: null,
      state: null,
      city: null,
      departments: [],
      ward: "",
      wards: [],
      bed: "",
      beds: [],
      selectedWard: null,
    };
    formik.resetForm({
      values: newValues,
    });
    setactiveStep(2);
    setactiveHeading(0);
  };

  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const handleExit = () => {
    formik.resetForm({
      values: initialValues,
    });
    dispatch(resetOnboardingAction());
    dispatch(logoutAction());
    setactiveStep(0);
    setactiveHeading(0);
    setOpen(false);
    navigate("/");
  };

  const resendMobileOtp = async () => {
    const phoneNumber = parsePhoneNumber(formik.values.phone);
    const formData = {
      user: {
        countryCode: phoneNumber?.countryCallingCode,
        mobile: phoneNumber?.nationalNumber,
        rawMobile: phoneNumber?.number,
      },
    };
    try {
      const response = await api.post("/auth/ob0", formData);
      console.log(`API call step ${activeStep}: `, response);
      if (response) {
        setOTPid(response.data.data._id);
        formik.setTouched({});
        formik.setSubmitting(false);
        // setactiveStep(activeStep + 1);
      }
    } catch (err) {
      if (err) {
        formik.setFieldError("phone", err.response?.data.message);
      }
    }
  };

  const resendEmailOtp = async () => {
    const formData = {
      user: {
        email: formik.values.email,
        user: obUser?._id,
      },
    };

    try {
      const response = await api.post("/auth/ob1", formData);
      console.log(`API call step ${activeStep}: `, response);
      if (response) {
        setOTPid(response.data.data._id);
        formik.setTouched({});
        formik.setSubmitting(false);
        // setactiveStep(activeStep + 1);
      }
    } catch (err) {
      if (err) {
        formik.setFieldError("email", err.response?.data.message);
      }
    }
  };

  // function to show part of the form based on the current step
  function renderStepContent(step) {
    switch (step) {
      case 0:
        return <MobilePage defaultCountry={defaultCountry} formik={formik} />;
      case 1:
        return (
          <MobileOtp
            resetMobile={resetMobile}
            formik={formik}
            resendMobileOtp={resendMobileOtp}
          />
        );

      case 2:
        return <EmailPage formik={formik} />;
      case 3:
        return (
          <EmailOtp
            resetEmail={resetEmail}
            formik={formik}
            resendEmailOtp={resendEmailOtp}
          />
        );

      case 4:
        return <PasswordPage formik={formik} />;
      case 5:
        return <HospitalPage formik={formik} />;
      case 6:
        return <DepartmentsPage formik={formik} />;
      case 7:
        return <ObWardsPage formik={formik} />;
      case 8:
        return <ObBedsPage formik={formik} />;

      default:
        return null;
    }
  }

  async function getGeoInfo() {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      if (response) {
        setdefaultCountry(response.data.country_code);
      }
    } catch (err) {
      if (err) setdefaultCountry("IN");
    }
  }

  useEffect(() => {
    getGeoInfo();
    return () => {
      dispatch(removeCountryListAction());
      dispatch(removeStateListAction());
      dispatch(removeCityListAction());
      dispatch(resetOnboardingAction());
    };
  }, []);

  console.log("formik", formik);
  return (
    <Box
      sx={{
        minHeight: "100vh",
        paddingTop: "8%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {activeStep === steps.length ? (
        <OnboardingSuccess />
      ) : (
        <>
          <Typography mb={3} variant="h4">
            Welcome to Daily Doc
          </Typography>
          <PaperElement elevation={1}>
            <Grid container>
              <Grid item xs={4}>
                <Stepper activeStep={activeHeading} orientation="vertical">
                  {headings.map((label, index) => (
                    <Step key={label}>
                      <StepLabel
                        optional={
                          index === 2 ? (
                            <Typography variant="caption">Last step</Typography>
                          ) : null
                        }
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={8}>
                <form
                  className="stepper-form"
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                >
                  <Box>{renderStepContent(activeStep)}</Box>
                  <Stack
                    sx={{ marginTop: 5 }}
                    direction="row-reverse"
                    spacing={2}
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      // fullWidth
                      disabled={formik.isSubmitting}
                      type="submit"
                      endIcon={<NavigateNextIcon />}
                    >
                      {isLastStep ? "Submit" : "Next"}
                    </Button>
                    {activeStep > 5 && (
                      <Button
                        onClick={() => handleBack(activeStep, activeHeading)}
                        variant="outlined"
                        startIcon={<ArrowBackIcon />}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={openDialog}
                    >
                      Exit
                    </Button>
                  </Stack>
                </form>
              </Grid>
            </Grid>
          </PaperElement>
        </>
      )}
      <ExitDialog
        handleExit={handleExit}
        open={open}
        closeDialog={closeDialog}
      />
    </Box>
  );
};

export default HospitalOnboarding;
