import { Fragment, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { loadUserAction, loginFailureAction } from "./redux/auth/authAction";

import setAuthToken from "./utilities/setAuthToken";
// import HomePage from './pages/home-page/HomePage';
import DashboardLayoutPage from "./pages/layout-pages/DashboardLayoutPage";
import LoginPage from "./pages/login-page/LoginPage";
import NotfoundPage from "./pages/notfound-page/NotfoundPage";
import PrivateRoute from "./components/routing/PrivateRoute";
import EmployeesPage from "./pages/employees-page/EmployeesPage";
import AddAdminPage from "./pages/add-admin-page/AddAdminPage";
import SetHospitalPage from "./pages/set-hospital-page/SetHospitalPage";
import SchedulesPage from "./pages/schedules-page/SchedulesPage";
import SubscriptionPage from "./pages/subscription-page/SubscriptionPage";
import ReportsPage from "./pages/reports-page/ReportsPage";
import InvoicesPage from "./pages/invoices-page/InvoicesPage";

import AddHospitalPage from "./pages/add-hospital-page/AddHospitalPage";
import MuiDemo from "./components/mui/MuiDemo";
import MobileCode from "./components/mui/MobileCode";
import VerticalLinearStepper from "./components/mui/MuiStepper";
import TwoFactorVerificationForm from "./components/formik/TwoFactorVerificationForm ";

import TestPages from "./pages/test-pages/TestPages";
import MuiFormik from "./components/mui/MuiFormik";
import Country from "./components/mui/Country";
import HospitalOnboardingPage from "./pages/hospital-onboarding-page/HospitalOnboardingPage";
import CsvReader from "./components/mui/CsvReader";
import AccessControlPage from "./pages/access-control-page/AccessControlPage";
import AccessControlCsvPage from "./pages/access-control-csv-page/AccessControlCsvPage";
import Wardspage from "./pages/wards-page/WardsPage";
import DepartmentsPage from "./pages/departments-page/DepartmentsPage";
import BedsPage from "./pages/beds-page/BedsPage";
import AccountLayout from "./pages/layout-pages/AccountLayout";
import ProfilePage from "./pages/profile-page/ProfilePage";
import ChangeHospitalPage from "./pages/change-hospital-page/ChangeHospitalPage";
import ChangePasswordPage from "./pages/change-password-page/ChangePasswordPage";
import EditHospitalOnboardingPage from "./pages/hospital-onboarding-page/EditHospitalOnboardingPage";
import AutocompleteForm from "./components/mui/AutocompleteForm";

import ForgotPasswordPage from "./pages/forgot-password-page/ForgotPasswordPage";
import ManageAdminLayout from "./pages/layout-pages/ManageAdminLayout";
import CurrentAdminsPage from "./pages/current-admins-page/CurrentAdminsPage";
import ProfileLayout from "./pages/layout-pages/ProfileLayout";
import ManageHospitalsLayout from "./pages/layout-pages/ManageHospitalsLayout";
import SettingsLayout from "./pages/layout-pages/SettingsLayout";
import RegisterAdminPage from "./pages/register-admin-page/RegisterAdminPage";
import MyHospitalsPage from "./pages/my-hospitals-page/MyHospitalsPage";
import ProfileHospitalsPage from "./pages/profile-hospitals/ProfileHospitalsPage";
import { SnackbarComp } from "./components/snackbar/Snackbar";
import DashboardPage from "./pages/dashboard-page/DashboardPage";
import GettingStartedPage from "./pages/instruction-pages/GettingStartedPage";
import InstructionsLayout from "./pages/layout-pages/InstructionsLayout";
import FeedbackPage from "./pages/feedback-page/FeedbackPage";
import AddUserInstructionsPage from "./pages/instruction-pages/AddUserInstructionsPage";
const App = ({ user, isAuthenticated, isLoading, hospital }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    setAuthToken(localStorage.token);
    dispatch(loadUserAction());
  }, []);

  return (
    <Fragment>
      <BrowserRouter>
        <SnackbarComp />
        <Routes>
          <Route path="/" element={<LoginPage />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route
            path="/onboarding"
            element={<HospitalOnboardingPage />}
          ></Route>
          <Route
            path="/edit-onboarding"
            element={<EditHospitalOnboardingPage />}
          ></Route>
          <Route
            path="/forgot-password"
            element={<ForgotPasswordPage />}
          ></Route>
          <Route
            path="register-admin/:secret"
            element={<RegisterAdminPage />}
          ></Route>
          <Route
            path="dashboard"
            element={
              <PrivateRoute>
                <DashboardLayoutPage />
              </PrivateRoute>
            }
          >
            <Route path="" element={<DashboardPage />}></Route>
            <Route path="employees" element={<EmployeesPage />}></Route>

            <Route path="profile" element={<ProfileLayout />}>
              <Route path="" element={<ProfilePage />}></Route>
              <Route
                path="hospitals"
                element={<ProfileHospitalsPage />}
              ></Route>
            </Route>
            <Route path="admins" element={<ManageAdminLayout />}>
              <Route path="" element={<CurrentAdminsPage />}></Route>
              <Route path="add-admin" element={<AddAdminPage />}></Route>
            </Route>

            <Route path="hospitals" element={<ManageHospitalsLayout />}>
              <Route path="" element={<MyHospitalsPage />}></Route>
              <Route path="add-hospital" element={<AddHospitalPage />}></Route>
            </Route>

            <Route path="account" element={<AccountLayout />}></Route>
            <Route path="settings" element={<SettingsLayout />}>
              <Route path="" element={<ChangePasswordPage />}></Route>
            </Route>
            <Route path="wards" element={<Wardspage />}></Route>
            <Route path="departments" element={<DepartmentsPage />}></Route>
            <Route path="beds" element={<BedsPage />}></Route>
            <Route path="schedules" element={<SchedulesPage />}></Route>
            <Route path="subscription" element={<SubscriptionPage />}></Route>
            <Route path="reports" element={<ReportsPage />}></Route>
            <Route path="invoices" element={<InvoicesPage />}></Route>
            <Route
              path="change-hospital"
              element={<ChangeHospitalPage />}
            ></Route>
            <Route
              path="access-control"
              element={<AccessControlPage />}
            ></Route>
            <Route
              path="upload-ac-csv"
              element={<AccessControlCsvPage />}
            ></Route>
            <Route path="instructions" element={<InstructionsLayout />}>
              <Route path="" element={<GettingStartedPage />}></Route>
              <Route
                path="add-users"
                element={<AddUserInstructionsPage />}
              ></Route>
            </Route>
            <Route path="feedback" element={<FeedbackPage />}></Route>
          </Route>
          <Route path="test" element={<TestPages />}>
            <Route path="mobile-code" element={<MobileCode />}></Route>
            <Route path="mui" element={<MuiDemo />}></Route>
            <Route path="OTP" element={<TwoFactorVerificationForm />}></Route>
            <Route
              path="mui-stepper"
              element={<VerticalLinearStepper />}
            ></Route>
            <Route path="muiformik" element={<MuiFormik />}></Route>
            <Route path="country" element={<Country />}></Route>
            <Route path="csv" element={<CsvReader />}></Route>
            <Route
              path="autocomplete-form"
              element={<AutocompleteForm />}
            ></Route>
          </Route>
          <Route path="*" element={<NotfoundPage />}></Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isLoading: state.auth.isLoading,
  user: state.auth.user,
  hospital: state.auth.hospital,
});

export default connect(mapStateToProps)(App);
