import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import CountryAutocomplete from "../reusables/CountryAutocomplete";
import StateAutocomplete from "../reusables/StateAutocomplete";
import CityAutocomplete from "../reusables/CityAutocomplete";

const GridElement = styled(Grid)(({ theme }) => ({
  ".MuiTextField-root": { marginTop: "0" },
}));

function HospitalPage(props) {
  const { formik } = props;

  return (
    <GridElement container rowSpacing={4} columnSpacing={{ sm: 2, md: 3 }}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="firstName"
          name="firstName"
          label="First Name"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="lastName"
          name="lastName"
          label="Last Name"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="hospitalName"
          name="hospitalName"
          label="Hospital Name"
          value={formik.values.hospitalName}
          onChange={formik.handleChange}
          error={
            formik.touched.hospitalName && Boolean(formik.errors.hospitalName)
          }
          helperText={formik.touched.hospitalName && formik.errors.hospitalName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id="hospitalShortName"
          name="hospitalShortName"
          label="Hospital Short Name"
          value={formik.values.hospitalShortName}
          onChange={formik.handleChange}
          error={
            formik.touched.hospitalShortName &&
            Boolean(formik.errors.hospitalShortName)
          }
          helperText={
            formik.touched.hospitalShortName && formik.errors.hospitalShortName
          }
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <CountryAutocomplete formik={formik} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StateAutocomplete
          formik={formik}
          countryId={formik.values.country?.countryId}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CityAutocomplete
          formik={formik}
          stateId={formik.values.state?.stateId}
        />
      </Grid>
    </GridElement>
  );
}

export default HospitalPage;
