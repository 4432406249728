import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

function ThirdPage(props) {
  const { formik } = props;
  return (
    <Grid container rowSpacing={4} columnSpacing={{ sm: 2, md: 3 }}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id='firstName'
          name='firstName'
          label='First Name'
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id='lastName'
          name='lastName'
          label='Last Name'
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id='hospitalName'
          name='hospitalName'
          label='Hospital Name'
          value={formik.values.hospitalName}
          onChange={formik.handleChange}
          error={
            formik.touched.hospitalName && Boolean(formik.errors.hospitalName)
          }
          helperText={formik.touched.hospitalName && formik.errors.hospitalName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id='country'
          name='country'
          label='Country'
          value={formik.values.country}
          onChange={formik.handleChange}
          error={formik.touched.country && Boolean(formik.errors.country)}
          helperText={formik.touched.country && formik.errors.country}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id='city'
          name='city'
          label='City'
          value={formik.values.city}
          onChange={formik.handleChange}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          id='state'
          name='state'
          label='State'
          value={formik.values.state}
          onChange={formik.handleChange}
          error={formik.touched.state && Boolean(formik.errors.state)}
          helperText={formik.touched.state && formik.errors.state}
        />
      </Grid>
    </Grid>
  );
}

export default ThirdPage;
